<template>

  <div @click.right.prevent @copy.prevent @paste.prevent>
  <h1 class="text-center">Directory</h1>
  <div class="table-main">

      <div class="table-title">
              <div class="titleone">
                  <p>Showrooms / Lines</p>
                  <span>Suites</span>
              </div> 
              <div class="titleone">
                  <p>Showrooms / Lines</p>
                  <span>Suites</span>
              </div> 
              <div class="titleone">
                <p>Z- Showrooms / Lines</p>
                <span>Suites</span>
              </div> 
       </div>

<div class="column_wrapper">
      
      <div v-for="item in stores">
      
            <a v-on:click="clickStore($event)" :id="item.suite">{{ item.name }} <br></a> <span>{{item.suite}}</span>
      </div>

</div>
  </div>
  </div>

</template>


<script>
export default {
  name: "Directory",
  computed:{
    stores() {
   
           return this.$store.state.suites.sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase())
                        return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase())
                        return 1;
                    return 0;
            });
    },
  },
  methods:{
      clickStore(e) {
          //this.target = e.target.id.slice(1);
          let floorId = parseInt(e.target.id.slice(2));
          let floorPath = 'floor1';
          if(floorId>=200){
              floorPath = 'floor2';
          }
          document.querySelector('#'+floorPath).classList.add('router-link-exact-active');
           this.$router.push({ path: '/'+floorPath+'/'+e.target.id, params: { id: e.target.id.slice(1) }})
       
           //console.log(document.querySelector('#floor2').classList.contains('router-link-active'));
            setTimeout(() => {
			        document.querySelector('#'+floorPath).classList.add('router-link-exact-active');
          }, 400);    
          // 
              
          
      }
  },
  mounted() {
    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }
}
</script>

<style scoped>
h1 {
  font-size: 4rem !Important;
}
.column_wrapper {
  column-count: 3;
}
.column_wrapper div{
  cursor:pointer;
}
.table-main {
    max-width: 1390px;
    margin: auto;
    border-top: solid 1px #ebebeb;
}
.table-title {
    display: flex;
    justify-content: space-between;
}
.titleone {
width: 32.6%;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #ebebeb;
    border-right: solid 1px #ebebeb;
    border-left: solid 1px #ebebeb;
}
.titleone p{
  margin-bottom:0px;
  padding: 10px;
}
.titleone span,
.column_wrapper div span  {
    padding: 10px;
    text-align: center;
    width: 90px;
    border-left: solid 1px #ebebeb;
}
.column_wrapper div {
display: flex;
    justify-content: space-between;
    border-bottom: solid 1px #ebebeb;
    border-right: solid 1px #ebebeb;
    border-left: solid 1px #ebebeb;
}
.column_wrapper div a{
padding: 10px;
color:#000;
text-decoration: none;
}

</style>