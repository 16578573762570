/**
 * ajaxRequest configuration*/
import axios from 'axios'
// axiosDefault configuration
axios.defaults.timeout = 10000;   // timeout
axios.defaults.baseURL = 'https://dcota.devplace.co/'; // Default address
axios.defaults.headers.common.Accept = 'application/json';
//Tidy up the data
axios.defaults.transformRequest = function (data) {
//data = Qs.stringify(data);
    data = JSON.stringify(data);
    return data;
};

// Routing request interception
// http request Interceptor
axios.interceptors.request.use(
    config => {

        return config;
    },
    error => {
        return Promise.reject(error.response);
    });

// Routing response interception
// http response Interceptor
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error.response)   // Error information returned by the return interface
    });

export default axios;