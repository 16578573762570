<template>
  <div>
    <!--<b-modal
        id="modal-center"
        centered
        :title="title"
        size="lg"
        @ok="handleOk"
        @hidden="resetModal"
    >
      <div class="row">
        <div class="col-4">
          <img :src="image" height="auto" width="300px">
        </div>
        <div class="col-2"></div>
        <div class="col-6">
          <p class="my-4"><strong>Suite:</strong>{{target}} {{ suite }}</p>
          <p clacc="my-4"><strong>Contact:</strong> {{ contact }}</p>
          <p clacc="my-4"><strong>Email:</strong> {{ email }}</p>
          <p clacc="my-4"><strong>Website:</strong> {{ website }}</p>
          <p clacc="my-4"><strong>Phone:</strong> {{ phone }}</p>
        </div>
      </div>

    </b-modal>-->

        <b-popover ref="popover" triggers="click" :target="`${targetzone}`" placement="auto" @show="onShow">
          
            <div class="row">
              <div class="col-12" v-if="image">
              <div class="popover-img">
                <img :src="image">
              </div>
              </div>
              <div class="col-12">
                <div class="popover-content">
                  
    
                  <p><strong><b-icon icon="house" class="border rounded" font-scale="2"></b-icon></strong> {{ title }} {{ suite }}</p>
                  <p><strong><b-icon icon="person" class="border rounded" font-scale="2"></b-icon></strong> {{ contact }}</p>
                  <p><strong><b-icon icon="envelope" class="border rounded" font-scale="2"></b-icon></strong> {{ email }}</p>
                  <p><strong><b-icon icon="globe" class="border rounded" font-scale="2"></b-icon></strong> {{ website }}</p>
                  <p><strong> <b-icon icon="phone" class="border rounded" font-scale="2"></b-icon></strong> {{ phone }}</p>
                   <b-button @click="onClose" size="sm" variant="danger">Cancel</b-button>
                </div>

              </div>
            </div>
        </b-popover>
  </div>
</template>

<script>
  export default {
    mounted() {
    

    },

    props:['title','image','suite','website','contact','phone','email','target','targetzone'],
    methods:{
      
      handleOk(bvModalEvent) {

      },
      onClose() {
        console.log("close");
          this.$root.$emit('bv::hide::popover')
      },
      resetModal() {
        this.$emit('unsel', this.target);
      },
    
      onShow(e) {
         let windowWidth = window.innerWidth/2;
         let widthRight = windowWidth+200;
         let widthLeft = windowWidth-200;
        
       
      //  var offsets =  document.getElementById('__bv_popover_14__').getBoundingClientRect();

          
         
          
          setTimeout(() => {
           //    const topPos =this.$refs['__bv_popover_14__'].getBoundingClientRect().x
              //  let element = document.getElementById("__bv_popover_14__");
              //  const topPos = element.offsetLeft;
             //  console.log(topPos);
            }, 1000);

         //const d = document.getElementById("div1");
          //const topPos = d.offsetTop;
           
      }

    },
    data() {
      return { }
    },
    created() {
      // Listen for an event on the root.
      this.$root.$on('bv::popover::show', () => {
        
      })
    }
  }
</script>

<style>
.popover-img img {
    width: 100%;
    height:100%;
   
}
.popover{
  max-width:320px ! important;
}
.popover-img {
   
    overflow: hidden;
}
.popover-body{
  padding:0px !important;
  box-shadow: 12px 10px 17px rgba(0,0,0,0.2);
  border: none !important;
}
.popover-content {
    padding: 17px 17px;
}
.popover-content p strong {
    display: inline-block;
}
.popover-content p {
    font-size: 14px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.popover-content svg {
    border: none !important;
    width: 21px;
    margin-right: 8px;
}
</style>