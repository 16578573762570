<template>
	<div class="floor4">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 636 285.3" style="enable-background:new 0 0 636 285.3;" xml:space="preserve">

<g>
	<g>
		<text transform="matrix(1 0 0 1 490.0762 231.986)" class="st0 st2 st7">DCOTA 4th Floor</text>
	</g>
	<g>
		<g>
			<polygon points="327,96.8 327,96.8 327.2,96.8 327.2,96.7 327,96.7 327,96.8 327,96.8 327,96.8 327,96.8 327,96.7 327.2,96.7 
				327.2,96.8 327,96.8 327,96.8 327,96.8 			"/>
		</g>
	</g>
	<path class="st3" d="M549.8,80.4c0-0.2-0.1-0.2-0.3,0C549.7,80.6,549.8,80.6,549.8,80.4"/>
	<polygon class="st4" points="555.9,113.1 546.9,104.1 547,79.9 545.1,77.1 502.7,77.2 499.6,80.3 499.6,145 490.6,153.9 
		419.2,153.9 419.7,106.1 447.5,105.8 447.7,40.6 507.6,40.5 508.3,43.5 511.7,43.6 512,30.2 534.6,30.3 534.9,43.8 537.9,43.7 
		538.1,41 599.1,41.3 598.9,92.8 596.5,92.9 596.8,95.8 598.4,96.6 598.4,113.3 	"/>
	<polygon class="st4" points="599.1,120.2 599.1,192 554.4,192.2 554.1,189.1 533.1,189 532.9,191.4 417.8,191.1 418.2,159.9 
		546.7,160 546.9,128.7 556.1,120.1 	"/>
	<path class="st5" d="M417.5,153.3l-43,0.4l-7.6-8.3l0.4-28.2l-7.7,0.3l-55.1-0.4l0.1,26.5l-9.8,10.4l-87.1-0.4l0.8-32.2h6.2
		l0.1-16.2h33l-0.1,16.4h6V73.3l35-0.2c0,0,5.9,0.4,10.3,4.8c4.3,4.3,4.8,11.9,4.8,11.9l9.9,0.2l0.8-17l104.7,0l-0.1,80.2
		L417.5,153.3z"/>
	<polygon class="st5" points="418.2,159.8 418.4,240.2 382.8,239.8 382.4,235.3 354.5,235.3 354.2,239.8 299.9,239.8 300.2,234.5 
		271.2,233.4 271.2,239.4 253.5,239.4 253.1,223.6 208.4,223.8 208.2,171.9 206,171.9 206,159.9 295.3,159.7 304,170.1 303.7,196.8 
		367.7,196.8 367.6,167.3 374.9,160.3 417.5,159.7 	"/>
	<path class="st6" d="M157.4,88.9c0,0,0.2-6.7,4-10.5c10.2-10.2,29.3-4.3,47-5.2l-0.1,80.7l-38,0c0,0-4.6,0.4-6.9-2.8
		c-1.5-2-2.8-6.5-2.8-6.5v-35.5l-5.3-1.2l-64.7,0.3l-0.6,45.2c0,0-28.8-0.6-32.9-0.6c-4.1,0-7.8-2.2-11.2-5.6
		c-3.4-3.4-3.8-10.3-3.8-10.3l0.7-64.5h105v15.6"/>
	<polygon class="st6" points="42.6,159.9 89.5,160.1 90.1,205 159.8,204.6 160.1,164.5 165.1,159.5 205.9,159.9 205.9,171.9 
		208.2,171.9 208.1,238.3 189.8,239 189.7,233.8 160.7,233.7 160.9,239 106.5,239 106.5,233.7 78.3,234 78,239 42.1,238.1 
		41.5,181.8 46.8,181.8 46.8,168.6 42.1,168.9 	"/>
	<path d="M208.3,153.5h-36.8h-1.9c-1.5,0-2.3-0.1-3.1-0.4c-0.8-0.4-1.6-1.1-2.9-2.6c-2.2-2.4-2.4-4.7-2.5-5.9c0-0.2,0-0.4,0-0.5
		l0-0.1l0,0v0l0,0V108h-71v44.6H57.9c-2.5,0-4.1-0.4-5.6-1.2c-1.5-0.8-3-2-5-3.8c-1.9-1.7-3.2-4.3-3.9-6.5c-0.4-1.1-0.6-2.1-0.8-2.8
		c-0.1-0.4-0.1-0.6-0.2-0.9c0-0.2,0-0.3,0-0.3l-0.4,0l0.4,0l0.6-64h104.4v16h10.4l0.1-0.3c0,0,0-0.1,0.1-0.3
		c0.2-0.7,0.6-2.5,1.3-4.4c0.7-1.9,1.6-4,2.6-5.1c2.4-2.7,7.4-5.3,8.9-5.9l-0.1-0.3v0.4h37.4l0.1,48.5h7V99.3h32.3v22.8h7V73.7h37.3
		c0.2,0,0.7,0.1,1.2,0.4c1.7,0.7,4.5,2.6,6.6,5.1c1.3,1.6,2.4,4.3,3,6.6c0.3,1.1,0.6,2.2,0.7,2.9c0.1,0.4,0.1,0.7,0.2,0.9
		c0,0.2,0.1,0.3,0.1,0.3l0.1,0.3h11V73.4h104.2v79.8h-44l-7.1-7.1v-29.1h-63.2V144l-9.4,9.5l-89.5,0.1l0,0.7l89.8-0.1l9.9-9.9v-26.6
		h61.7v28.7l7.5,7.5h45.1V72.6H313.7v16.9h-9.9v0.4l0.4-0.1c0,0-0.3-1.9-1-4.2c-0.7-2.3-1.7-5.1-3.1-6.9c-1.4-1.7-3.2-3.2-4.7-4.2
		c-0.8-0.5-1.5-0.9-2.1-1.2c-0.6-0.3-1.1-0.4-1.5-0.4h-38.1v48.4h-5.5V98.5h-33.8V121h-5.5l-0.1-48.5h-38.2l-0.1,0
		c-1.6,0.7-6.6,3.2-9.2,6.1c-1.3,1.5-2.3,4-3,6.2c-0.7,2.2-1.1,4-1.1,4l0.4,0.1v-0.4h-9.3v-16H42.2l-0.6,64.8l0,0c0,0,0.2,1.8,1,4.1
		c0.8,2.3,2,5,4.1,6.8c2,1.7,3.5,3,5.2,3.9c1.7,0.9,3.5,1.3,6,1.3H91v-44.6h69.5v35.1h0.4l-0.4-0.1c0,0-0.1,0.3-0.1,0.8
		c0,1.3,0.3,3.8,2.6,6.4c1.3,1.5,2.2,2.3,3.1,2.8c1,0.5,1.9,0.5,3.4,0.5c0.5,0,1.2,0,1.9,0h36.8V153.5z"/>
	<polygon points="208.3,172 206.2,172 206.2,159.3 165.4,159.3 159.6,165.1 159.6,204.2 90.7,204.2 90.4,159.6 42.2,159.6 
		42.2,169.4 47.2,169.4 47.2,181.4 41.6,181.4 41.6,239 78.8,239 78.8,234.3 106.8,234.3 106.8,239 161.2,239 161.2,234 189.5,234 
		189.5,239 208.6,239 208.6,217.2 207.9,217.2 207.9,238.2 190.3,238.2 190.3,233.2 160.5,233.2 160.5,238.2 107.6,238.2 
		107.6,233.5 78.1,233.5 78.1,238.2 42.3,238.2 42.3,182.2 47.9,182.2 47.9,168.6 42.9,168.6 42.9,160.3 89.6,160.3 89.9,204.9 
		160.3,204.9 160.3,165.4 165.7,160 205.4,160 205.4,172.8 208.3,172.8 	"/>
	<polyline points="208.7,218.6 208.7,217.8 208,217.8 208,218.6 	"/>
	<polygon points="208.7,208.7 208.7,172.1 205.8,172.1 205.8,172.8 207.9,172.8 207.9,208.7 	"/>
	<polygon points="205.8,160 294.9,160 303.7,170.1 303.7,197.2 367.9,197.2 367.9,167.6 375,160.5 417.4,160.5 417.4,239.8 
		383.2,239.8 383.2,234.5 353.8,234.2 353.8,239.2 301.1,239.5 301.1,233.9 271.3,233.9 271.3,239.5 254,239.5 254,223.2 
		208.7,223.2 208.7,207.1 207.9,207.1 207.9,224 253.2,224 253.2,240.2 272.1,240.2 272.1,234.6 300.3,234.6 300.3,240.2 354.6,240 
		354.6,235 382.4,235.2 382.4,240.5 418.1,240.5 418.1,159.7 374.7,159.7 367.1,167.3 367.1,196.4 304.5,196.4 304.5,169.8 
		295.3,159.3 205.8,159.3 	"/>
	<polygon points="419.3,105.9 419.3,106.2 448.1,106.2 447.8,41 507.6,41 507.6,43.8 512.1,43.8 512.1,30.5 534.5,30.5 534.5,44.1 
		538.3,44.1 538.3,41.3 598.8,41.3 598.8,92.1 595.9,92.1 595.9,95.1 598,97.1 598,112.9 556.2,112.9 547.4,104.1 547.4,80 
		545.2,76.6 502.7,76.6 499.2,79.8 499.2,144.7 490.7,153.2 419.4,153.2 419.7,105.9 419.3,105.9 419.3,106.2 419.3,105.9 
		419,105.9 418.6,153.9 491,153.9 499.9,145 499.9,80.1 503,77.3 544.8,77.3 546.6,80.2 546.6,104.4 555.9,113.6 598.7,113.6 
		598.7,96.8 596.7,94.8 596.7,92.8 599.5,92.8 599.5,40.6 537.6,40.6 537.6,43.4 535.2,43.4 535.2,29.7 511.4,29.7 511.4,43 
		508.4,43 508.4,40.3 447,40.3 447.4,105.5 419,105.5 419,105.9 	"/>
	<polygon points="599.1,120.2 599.1,119.8 555.9,119.8 546.5,129.1 546.5,159.6 417.4,159.6 417.4,191.3 533.3,191.3 533.3,188.8 
		553.8,188.8 553.8,191.9 599.5,191.9 599.5,119.8 599.1,119.8 599.1,120.2 598.8,120.2 598.8,191.1 554.6,191.1 554.6,188.1 
		532.6,188.1 532.6,190.5 418.1,190.5 418.1,160.4 547.2,160.4 547.2,129.4 556.2,120.5 599.1,120.5 599.1,120.2 598.8,120.2 	"/>
	<path d="M79,72.9c0,0,0.9-0.3,2.6-0.7c5.9-1.7,20.6-5.8,34.8-9.6c7.1-1.9,14.1-3.7,19.7-5c2.8-0.7,5.3-1.2,7.3-1.6
		c2-0.4,3.6-0.6,4.4-0.7c0.7,0,1.4,0,2.1,0c3.7,0,7.1,0.4,10.1,1.6c3,1.1,5.5,3,7.7,5.8c2.4,3.1,3.8,5.8,4.7,7.6
		c0.4,0.9,0.7,1.7,0.9,2.2c0.1,0.2,0.2,0.4,0.2,0.6l0,0.1l0,0l0.7-0.2c0,0-1.2-4.5-6-10.8c-2.2-2.9-4.9-4.8-8-6
		c-3.1-1.2-6.5-1.6-10.3-1.6c-0.7,0-1.4,0-2.1,0c-1.1,0-3,0.4-5.5,0.9c-17.3,3.5-63.7,16.8-63.7,16.8L79,72.9z"/>
	<path d="M322.3,57.4c0,0,0,0-0.8-0.1c-0.8-0.1-2-0.3-3.3-0.4c-2.6-0.3-6-0.6-8.9-0.6c-1.7,0-3.2,0.1-4.2,0.4
		c-4.8,1.2-12.3,5.8-16.3,16.7l-0.7-0.3c4.1-11.1,11.8-16,16.9-17.2c1.2-0.3,2.7-0.4,4.4-0.4c5.3,0,12.1,1,13.3,1.2
		c0.1,0,0.2,0,0.4,0.1l54.9,15.7l-1.2,0.6l-53.5-15.4L322.3,57.4z"/>
	<polygon points="68.5,153.1 68.5,153.5 84,153.5 84,139.9 68.1,139.9 68.1,153.5 68.5,153.5 68.5,153.1 68.9,153.1 68.9,140.7 
		83.2,140.7 83.2,152.7 68.5,152.7 68.5,153.1 68.9,153.1 	"/>
	<polygon points="70.1,165 70.1,165.4 84,165.4 84,159.5 69.7,159.5 69.7,165.4 70.1,165.4 70.1,165 70.5,165 70.5,160.3 
		83.2,160.3 83.2,164.6 70.1,164.6 70.1,165 70.5,165 	"/>
	<polygon points="68.8,153 68.8,153.4 80.4,153.4 80.4,146.1 68.4,146.1 68.4,153.4 68.8,153.4 68.8,153 69.2,153 69.2,147 
		79.5,147 79.5,152.5 68.8,152.5 68.8,153 69.2,153 	"/>
	<polygon points="174,172.9 174,173.2 199.1,173.2 199.1,159.7 173.7,159.7 173.7,173.2 174,173.2 174,172.9 174.4,172.9 
		174.4,160.4 198.3,160.4 198.3,172.5 174,172.5 174,172.9 174.4,172.9 	"/>
	<polygon points="198.7,166.3 198.7,166.7 206,166.7 206,159.7 198.3,159.7 198.3,166.7 198.7,166.7 198.7,166.3 199.1,166.3 
		199.1,160.4 205.2,160.4 205.2,165.9 198.7,165.9 198.7,166.3 199.1,166.3 	"/>
	<polyline points="188.8,164 188.8,172.5 189.5,172.5 189.5,164 	"/>
	<polyline points="214.8,105.5 247.8,105.5 247.8,104.7 214.8,104.7 	"/>
	<polygon points="221.3,159.7 221.3,172.5 217.7,172.5 217.7,200.8 218.5,200.8 218.5,173.2 222.1,173.2 222.1,159.7 	"/>
	<polygon points="255.2,159.3 255.2,223.1 253.3,223.1 253.3,223.9 256,223.9 256,159.3 	"/>
	<polygon points="240.4,159.7 240.4,200.4 208.2,200.4 208.2,201.2 241.2,201.2 241.2,159.7 	"/>
	<polyline points="229.2,159.4 229.2,177.2 230,177.2 230,159.4 	"/>
	<polygon points="240.8,177.1 221.7,177.1 221.3,185.4 240.5,185.4 240.5,184.7 222.1,184.7 222.4,177.8 240.8,177.8 	"/>
	<polyline points="222.1,195.5 222.1,201.2 222.9,201.2 222.9,195.5 	"/>
	<polyline points="222,195.9 240.8,195.9 240.8,195.2 222,195.2 	"/>
	<polyline points="236.1,195.9 236.1,200.7 236.9,200.7 236.9,195.9 	"/>
	<polygon points="259.5,160 259.5,213.9 255.8,213.9 255.8,214.7 260.2,214.7 260.2,160 	"/>
	<polyline points="319.3,196.9 319.3,239.7 320.1,239.7 320.1,196.9 	"/>
	<polygon points="287.5,189 287.5,189.4 304.3,189.4 304.3,182.7 287.1,182.7 287.1,189.4 287.5,189.4 287.5,189 287.9,189 
		287.9,183.4 303.6,183.4 303.6,188.6 287.5,188.6 287.5,189 287.9,189 	"/>
	<polygon points="394.7,153.4 394.7,153.8 401,153.8 401,137.9 394.3,137.9 394.3,153.8 394.7,153.8 394.7,153.4 395,153.4 
		395,138.7 400.2,138.7 400.2,153.1 394.7,153.1 394.7,153.4 395,153.4 	"/>
	<polygon points="400.6,153.3 400.6,153.7 419.4,153.7 419.4,139.9 400.2,139.9 400.2,153.7 400.6,153.7 400.6,153.3 401,153.3 
		401,140.7 418.6,140.7 418.6,153 400.6,153 400.6,153.3 401,153.3 	"/>
	<polygon points="400.7,173.5 400.7,173.9 407.3,173.9 407.3,159.8 400.3,159.8 400.3,173.9 400.7,173.9 400.7,173.5 401.1,173.5 
		401.1,160.5 406.6,160.5 406.6,173.1 400.7,173.1 400.7,173.5 401.1,173.5 	"/>
	<polygon points="406.9,173.5 406.9,173.9 418.1,173.9 418.1,159.8 406.6,159.8 406.6,173.9 406.9,173.9 406.9,173.5 407.3,173.5 
		407.3,160.5 417.4,160.5 417.4,173.1 406.9,173.1 406.9,173.5 407.3,173.5 	"/>
	<polygon points="423.3,176.1 423.3,176.5 435.2,176.5 435.2,163.9 422.9,163.9 422.9,176.5 423.3,176.5 423.3,176.1 423.7,176.1 
		423.7,164.7 434.4,164.7 434.4,175.8 423.3,175.8 423.3,176.1 423.7,176.1 	"/>
	<polygon points="434.8,181.8 434.8,182.2 444.7,182.2 444.7,163.9 434.4,163.9 434.4,182.2 434.8,182.2 434.8,181.8 435.2,181.8 
		435.2,164.7 444,164.7 444,181.4 434.8,181.4 434.8,181.8 435.2,181.8 	"/>
	<polygon points="445.3,176.4 445.3,176.7 450.6,176.7 450.6,170.8 444.9,170.8 444.9,176.7 445.3,176.7 445.3,176.4 445.7,176.4 
		445.7,171.6 449.9,171.6 449.9,176 445.3,176 445.3,176.4 445.7,176.4 	"/>
	<polygon points="444.3,181.8 444.3,182.2 450.7,182.2 450.7,176.1 443.9,176.1 443.9,182.2 444.3,182.2 444.3,181.8 444.7,181.8 
		444.7,176.9 449.9,176.9 449.9,181.4 444.3,181.4 444.3,181.8 444.7,181.8 	"/>
	<polygon points="449.9,159.8 449.9,181.4 447.6,181.4 447.6,190.9 448.4,190.9 448.4,182.1 450.7,182.1 450.7,159.8 	"/>
	<polyline points="466.3,160.3 466.3,190.9 467.1,190.9 467.1,160.3 	"/>
	<polyline points="482.1,160.3 482.1,191.2 482.9,191.2 482.9,160.3 	"/>
	<polyline points="498.9,160.3 498.9,191 499.7,191 499.7,160.3 	"/>
	<polygon points="499.3,118 477.3,118 467.2,107.9 450.3,107.9 447.4,104.9 447.3,153.7 448,153.7 448.1,106.8 450,108.6 
		466.9,108.6 477,118.7 499.3,118.7 	"/>
	<polyline points="447.7,93.4 477.7,93.4 477.7,92.7 447.7,92.7 	"/>
	<polygon points="453.2,99.5 453.2,99.9 459.2,99.9 459.2,92.8 452.9,92.8 452.9,99.9 453.2,99.9 453.2,99.5 453.6,99.5 453.6,93.6 
		458.5,93.6 458.5,99.2 453.2,99.2 453.2,99.5 453.6,99.5 	"/>
	<polygon points="477.9,99.2 477.9,99.6 484.6,99.6 484.6,91.8 477.5,91.8 477.5,99.6 477.9,99.6 477.9,99.2 478.3,99.2 478.3,92.6 
		483.7,92.6 483.7,98.8 477.9,98.8 477.9,99.2 478.3,99.2 	"/>
	<polygon points="484.2,99.2 484.2,99.6 496.4,99.6 496.4,91.9 483.8,91.9 483.8,99.6 484.2,99.6 484.2,99.2 484.5,99.2 484.5,92.7 
		495.6,92.7 495.6,98.8 484.2,98.8 484.2,99.2 484.5,99.2 	"/>
	<polygon points="496.2,99.2 496.2,99.6 499.8,99.6 499.8,95.2 495.8,95.2 495.8,99.6 496.2,99.6 496.2,99.2 496.6,99.2 496.6,96 
		499,96 499,98.8 496.2,98.8 496.2,99.2 496.6,99.2 	"/>
	<polygon points="516.1,77.2 516.1,44 511.9,42.9 511.7,43.7 515.4,44.6 515.4,77.2 	"/>
	<polygon points="545.4,76.8 545.4,64.5 557.9,52 557.9,41.3 557.1,41.3 557.1,51.7 544.6,64.2 544.6,76.8 	"/>
	<polygon points="574.2,113 574.2,113.4 579.9,113.4 579.9,103.8 573.8,103.8 573.8,113.4 574.2,113.4 574.2,113 574.5,113 
		574.5,104.5 579.1,104.5 579.1,112.6 574.2,112.6 574.2,113 574.5,113 	"/>
	<polygon points="579.5,113.2 579.5,113.5 598.6,113.5 598.6,96.5 579.2,96.5 579.2,113.5 579.5,113.5 579.5,113.2 579.9,113.2 
		579.9,97.3 597.9,97.3 597.9,112.8 579.5,112.8 579.5,113.2 579.9,113.2 	"/>
	<polygon points="565.4,147.9 565.4,148.2 571.4,148.2 571.4,141.6 565,141.6 565,148.2 565.4,148.2 565.4,147.9 565.8,147.9 
		565.8,142.3 570.6,142.3 570.6,147.5 565.4,147.5 565.4,147.9 565.8,147.9 	"/>
	<polygon points="589.2,148.5 589.2,148.9 595.2,148.9 595.2,142.2 588.9,142.2 588.9,148.9 589.2,148.9 589.2,148.5 589.6,148.5 
		589.6,143 594.4,143 594.4,148.1 589.2,148.1 589.2,148.5 589.6,148.5 	"/>
	<polygon points="286.8,91.2 286.8,73.8 286,73.8 286,119.9 304.6,119.9 304.6,119.2 286.8,119.2 286.8,73.8 286,73.8 286,91.2 	"/>
	<polygon points="173.2,73.6 173.2,107.9 161.2,107.9 161.2,108.6 173.9,108.6 173.9,73.6 	"/>
	<polygon points="208.1,205 189,205 189,233.6 189.7,233.6 189.7,205.7 208.1,205.7 	"/>
	<polyline points="535.2,76.9 535.2,44 534.4,44 534.4,76.9 	"/>
	<polygon points="159.8,205 167.5,205 167.5,233.3 168.3,233.3 168.3,204.2 159.8,204.2 	"/>
	<polygon points="140.4,238.2 140.4,225 147.5,225 147.5,205 146.8,205 146.8,224.3 139.6,224.3 139.6,238.2 	"/>
	<polyline points="134.1,108.3 134.1,73.4 133.3,73.4 133.3,108.3 	"/>
	<g>
		<text transform="matrix(1 0 0 1 97.3389 157.8063)" class="st0 st7 st9">ATRIUM A</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 304.4326 158.0104)" class="st0 st7 st9">ATRIUM B</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 504.1924 158.0094)" class="st0 st7 st9">ATRIUM C</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 220.502 207.1715)" class="st0 st7 st10">DCOTA </text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 221.8877 218.5944)" class="st0 st7 st11">Offices</text>
	</g>
	<text transform="matrix(1 0 0 1 109.7344 249.6461)"><tspan x="0" y="0" class="st0 st7 st12">O</tspan><tspan x="6" y="0" class="st0 st7 st12">F</tspan><tspan x="10.3" y="0" class="st0 st7 st12">F</tspan><tspan x="14.9" y="0" class="st0 st7 st12">I</tspan><tspan x="17.1" y="0" class="st0 st7 st12">CE</tspan><tspan x="27.1" y="0" class="st0 st7 st12"> </tspan><tspan x="29" y="0" class="st0 st7 st12">S</tspan><tspan x="33.5" y="0" class="st0 st7 st12">P</tspan><tspan x="38.3" y="0" class="st0 st7 st12">A</tspan><tspan x="43.2" y="0" class="st0 st7 st12">C</tspan><tspan x="48.3" y="0" class="st0 st7 st12">E</tspan></text>
	<text transform="matrix(1 0 0 1 299.042 249.6461)"><tspan x="0" y="0" class="st0 st7 st12">O</tspan><tspan x="6" y="0" class="st0 st7 st12">F</tspan><tspan x="10.3" y="0" class="st0 st7 st12">F</tspan><tspan x="14.9" y="0" class="st0 st7 st12">I</tspan><tspan x="17.1" y="0" class="st0 st7 st12">CE</tspan><tspan x="27.1" y="0" class="st0 st7 st12"> </tspan><tspan x="29" y="0" class="st0 st7 st12">S</tspan><tspan x="33.5" y="0" class="st0 st7 st12">P</tspan><tspan x="38.3" y="0" class="st0 st7 st12">A</tspan><tspan x="43.2" y="0" class="st0 st7 st12">C</tspan><tspan x="48.3" y="0" class="st0 st7 st12">E</tspan></text>
	<text transform="matrix(1 0 0 1 490.0767 201.2321)"><tspan x="0" y="0" class="st0 st7 st12">O</tspan><tspan x="6" y="0" class="st0 st7 st12">F</tspan><tspan x="10.3" y="0" class="st0 st7 st12">F</tspan><tspan x="14.9" y="0" class="st0 st7 st12">I</tspan><tspan x="17.1" y="0" class="st0 st7 st12">CE</tspan><tspan x="27.1" y="0" class="st0 st7 st12"> </tspan><tspan x="29" y="0" class="st0 st7 st12">S</tspan><tspan x="33.5" y="0" class="st0 st7 st12">P</tspan><tspan x="38.3" y="0" class="st0 st7 st12">A</tspan><tspan x="43.2" y="0" class="st0 st7 st12">C</tspan><tspan x="48.3" y="0" class="st0 st7 st12">E</tspan></text>
	<polygon points="90.9,109.3 90.9,103.7 42.7,103.7 42.7,104.5 90.1,104.5 90.1,109.3 	"/>
	<g>
		<text transform="matrix(1 0 0 1 172.2158 183.0983)" class="st0 st7 st11">DCOTA </text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 175.4463 194.5475)" class="st0 st7 st13">A-450</text>
	</g>
	<text transform="matrix(1 0 0 1 210.6074 212.903)" class="st0 st7 st10">Administrative</text>
	<text transform="matrix(1 0 0 1 162.9404 188.9537)" class="st0 st7 st11">Fitness Center</text>
</g>
<g>
	<g>
		<rect x="348" y="140.1" width="12.1" height="12.1"/>
		<path class="st14" d="M352.6,141c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
			v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L352.6,141z"/>
		<path class="st14" d="M355.5,143.7c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0v-1.2
			h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L355.5,143.7z"/>
		<path class="st14" d="M358,144.3h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
			C359.3,144.8,358.7,144.3,358,144.3 M358,144.8c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
			v-4.5c0-0.4,0.3-0.8,0.8-0.8H358z"/>
		<path class="st14" d="M353.2,146.2c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C351.5,147.3,353.2,147.3,353.2,146.2"/>
		<path class="st14" d="M353,147.3c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C353.6,148.6,353.7,147.6,353,147.3"/>
		<path class="st14" d="M356.6,146.2c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C355,147.3,356.6,147.3,356.6,146.2"/>
		<path class="st14" d="M356.5,147.3c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C357,148.6,357.2,147.6,356.5,147.3"/>
	</g>
	<g>
		<rect x="348" y="161.2" width="12.1" height="12.1"/>
		<path class="st14" d="M352.6,162.1c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1
			h0.5v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L352.6,162.1z"/>
		<path class="st14" d="M355.5,164.8c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0V162
			h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L355.5,164.8z"/>
		<path class="st14" d="M358,165.4h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
			C359.3,166,358.7,165.4,358,165.4 M358,165.9c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8v-4.5
			c0-0.4,0.3-0.8,0.8-0.8H358z"/>
		<path class="st14" d="M353.2,167.3c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C351.5,168.4,353.2,168.4,353.2,167.3"/>
		<path class="st14" d="M353,168.5c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C353.6,169.7,353.7,168.8,353,168.5"/>
		<path class="st14" d="M356.6,167.3c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C355,168.4,356.6,168.4,356.6,167.3"/>
		<path class="st14" d="M356.5,168.5c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C357,169.7,357.2,168.8,356.5,168.5"/>
	</g>
</g>
<g>
	<g>
		<rect x="529.7" y="99.9" width="12.1" height="12.1"/>
		<path class="st14" d="M534.3,100.8c0-0.1-0.1-0.1-0.2-0.1s-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.2
			h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L534.3,100.8z"/>
		<path class="st14" d="M537.2,103.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1
			l-0.5,0v-1.2h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L537.2,103.5z"/>
		<path class="st14" d="M539.7,104.1h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
			C540.9,104.7,540.4,104.1,539.7,104.1 M539.7,104.6c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
			v-4.5c0-0.4,0.3-0.8,0.8-0.8H539.7z"/>
		<path class="st14" d="M534.8,106c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C533.2,107.1,534.8,107.1,534.8,106"/>
		<path class="st14" d="M534.7,107.2c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C535.3,108.4,535.4,107.5,534.7,107.2"/>
		<path class="st14" d="M538.3,106c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C536.6,107.1,538.3,107.1,538.3,106"/>
		<path class="st14" d="M538.1,107.2c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C538.7,108.4,538.8,107.5,538.1,107.2"/>
	</g>
	<g>
		<rect x="529.7" y="121" width="12.1" height="12.1"/>
		<path class="st14" d="M534.3,121.9c0-0.1-0.1-0.1-0.2-0.1s-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.2
			h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L534.3,121.9z"/>
		<path class="st14" d="M537.2,124.7c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1
			l-0.5,0v-1.2h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L537.2,124.7z"/>
		<path class="st14" d="M539.7,125.2h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
			C540.9,125.8,540.4,125.2,539.7,125.2 M539.7,125.7c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
			v-4.5c0-0.4,0.3-0.8,0.8-0.8H539.7z"/>
		<path class="st14" d="M534.8,127.1c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C533.2,128.3,534.8,128.3,534.8,127.1"/>
		<path class="st14" d="M534.7,128.3c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C535.3,129.5,535.4,128.6,534.7,128.3"/>
		<path class="st14" d="M538.3,127.1c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C536.6,128.3,538.3,128.3,538.3,127.1"/>
		<path class="st14" d="M538.1,128.3c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
			c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C538.7,129.5,538.8,128.6,538.1,128.3"/>
	</g>
</g>
<g>
	<rect x="580.7" y="107.6" width="16.5" height="17.2"/>
	<path class="st14" d="M591.4,119.4c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5H591
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V119.4z"/>
	<path class="st14" d="M584.9,117.9h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2h-0.2v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2h-0.2v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V117.9z"/>
	<path class="st14" d="M592.6,111.6c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C593.9,111,593.3,111.6,592.6,111.6"/>
	<path class="st14" d="M583.5,110.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C584.1,111.6,583.5,111,583.5,110.3"/>
</g>
<g>
	<rect x="401.6" y="147.6" width="16.5" height="17.2"/>
	<path class="st14" d="M412.3,159.5c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5h-0.3
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V159.5z"/>
	<path class="st14" d="M405.8,157.9h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2h-0.2v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2h-0.2v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V157.9z"/>
	<path class="st14" d="M413.5,151.6c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C414.8,151.1,414.2,151.6,413.5,151.6"/>
	<path class="st14" d="M404.4,150.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C405,151.6,404.4,151,404.4,150.3"/>
</g>
<g>
	<rect x="178.1" y="147.6" width="16.5" height="17.2"/>
	<path class="st14" d="M188.9,159.5c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5h-0.3
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V159.5z"/>
	<path class="st14" d="M182.4,157.9h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2h-0.2v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2h-0.2v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V157.9z"/>
	<path class="st14" d="M190.1,151.6c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C191.4,151.1,190.8,151.6,190.1,151.6"/>
	<path class="st14" d="M180.9,150.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C181.5,151.6,180.9,151,180.9,150.3"/>
</g>
<rect class="st15" width="636" height="285.3"/>
</svg>
	<store-modal
      :title="store.name"
      :image="store.business_image"
      :suite="store.suite"
      :website="store.website"
      :contact="store.contact"
      :phone="store.phone"
      :email="store.email"
      :target="target"
      :targetzone="targetzone"
    />
	</div>
</template>

<script>
// @ is an alias to /src
import StoreModal from '@/components/StoreModal'
export default {
  name: 'floor4',
  components:{
    StoreModal,
  },
  data(){
    return {
      store:'B-100',
    }
  },
  methods:{
    clickStore(e) {
		setTimeout(() => {
                  this.$root.$emit('bv::hide::popover');
            }, 0);
			this.targetzone = '';
      e.target.className.baseVal = "selected";
      this.target = e.target.id;
      this.store = this.storeData(this.target);
      this.targetzone = this.target;

      //this.$bvModal.show('modal-center');
	  setTimeout(() => {
                  this.$root.$emit('bv::show::popover',this.targetzone)
            }, 1000);
    },
    resetModal() {
      console.log("reset modal");
    },

    storeData(payload) {
      return this.stores.find(x => x.suite === payload)
    },
	showStorePopover(){
          this.targetzone = this.$route.params.id;
          if (typeof(this.$route.params.id) !== 'undefined'){

		
			setTimeout(() => {
					this.store = this.storeData(this.$route.params.id);
					this.$root.$emit('bv::show::popover',this.targetzone);
				}, 400);
		}
    }
  },
  mounted() {
    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  },
  computed:{
    stores() {
      return this.$store.state.suites;
    }
  },

  created: function(){
        if (typeof(this.$route.params.id) !== 'undefined'){
			this.showStorePopover()
	}
  }
}
</script>

<style scoped type="text/css">
.selected {
	stroke: #002868 !important;
	stroke-width: 1px;
	stroke-linejoin: round;
	fill: #FFFFFF !important;
	cursor: pointer;
	fill-opacity: .7;
	}
	.st0{fill:#000100;}
	.st1{font-family: 'Montserrat', sans-serif;}
	.st2{font-size:11px;}
	.st3{fill:#E6E7E7;}
	.st4{fill:#DAE29F;}
	.st5{fill:#C5E1F5;}
	.st6{fill:#8FC4E3;}
	.st7{font-family: 'Montserrat', sans-serif;}
	.st8{font-size:5.9417px;}
	.st9{font-size:6.6326px;}
	.st10{font-size:5.458px;}
	.st11{font-size:5.3199px;}
	.st12{font-size:6.9089px;}
	.st13{font-size:4.9053px;}
	.st14{fill:#FFFFFF;}
	.st15{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
</style>