<template>
  <div id="app">
    <div id="nav">
      <router-link to="/" tag="b-button">Directory</router-link>
      <router-link to="/floor1" id="floor1" tag="b-button">Floor 1</router-link>
      <router-link to="/floor2" id="floor2" tag="b-button">Floor 2</router-link>
      <router-link to="/floor3" tag="b-button">Floor 3</router-link>
      <router-link to="/floor4" tag="b-button">Floor 4</router-link>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  created: function () {
    this.$store.dispatch('loadSuites')
  }
}
</script>
<style scoped>
button {
  width: 300px;
  text-align: center;
  font-size: 3rem !Important;
}
.router-link-exact-active.router-link-active{
  color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
}
</style>
