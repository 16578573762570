import Vue from 'vue'
import VueRouter from 'vue-router'
import Directory from '../views/Directory.vue'
import Floor1 from '../views/Floor1.vue'
import Floor2 from '../views/Floor2.vue'
import Floor3 from '../views/Floor3.vue'
import Floor4 from '../views/Floor4.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Directory',
    component: Directory
  },{
    path: '/floor1',
    name: 'Floor 1',
    component: Floor1
  },
  {
    path: '/floor2',
    name: 'Floor 2',
    component: Floor2
  },
  {
    path: '/floor3',
    name: 'Floor 3',
    component: Floor3
  },
  {
    path: '/floor4',
    name: 'Floor 4',
    component: Floor4
  },
  {
    path: '/floor1/:id',
    name: 'Floor 1',
    component: Floor1,
    props: true
  }
  ,
  {
    path: '/floor2/:id',
    name: 'Floor 2',
    component: Floor2,
    props: true
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
