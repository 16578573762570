<template>
  <div class="floor1">
  <svg version="1.1" id="floor1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 644.8 255" style="enable-background:new 0 0 644.8 255;" xml:space="preserve">

<polygon class="st0" points="564.6,122.3 564.6,127.5 570.3,127.5 594.4,149.5 620.3,149.5 620.3,122.3 "/>
<g id="A-104">
	<polygon id="A104" class="st1" points="24,125.2 24.7,127.3 25.4,129.3 26.6,131.5 27.9,133.5 30.3,135.6 32.5,137.3 35.1,138.3 
		37.5,139 39.7,139.1 41.7,139.3 70.1,139.3 75.8,134.3 75.8,117.1 24,117.1 	"/>
</g>
<g id="A-108">
	<polygon id="A108" class="st1" points="56.1,105.1 35.6,84.9 23.4,84.9 23.4,116.7 75.2,116.7 75.2,105.1 	"/>
</g>
<g id="A-112">
	<polygon id="A112" class="st1" points="65.7,82.3 67,81 50.2,65.6 50.2,65.8 29,65.7 28.8,61 23.8,61 23.8,85 36,85 56.5,105.3 
		75.6,105.3 75.6,91.6 	"/>
</g>
<g id="A-116">
	<polygon id="A116" class="st1" points="68.4,78.6 79.5,89.1 105.5,89 105.5,81.8 95.2,81.8 95.2,52.5 90.5,53.5 79.3,56.1 69,58.5 
		59.1,60.8 49.7,62.9 49.7,65.3 66.5,80.7 	"/>
</g>
<g id="A-117">
	<g>
		<polygon id="A117" class="st1" points="113.1,75.2 113.1,60.8 95.3,60.8 95.3,82.3 105.7,82.3 105.7,89.4 116.3,89.4 116.3,78.8 
					"/>
	</g>
</g>
<g id="A-118">
	<polygon id="A118" class="st1" points="130.1,44.6 126.2,45.5 117.1,47.9 108,49.9 100,51.7 95.4,52.7 95.4,60.6 113.3,60.6 
		113.3,75.1 116.4,78.5 116.4,89.2 130.1,89.2 	"/>
</g>
<g id="A-123">
	<polygon id="A123" class="st1" points="81.5,94.8 81.4,120.7 144.4,120.6 144,94.8 	"/>
</g>
<g id="A-123_00000124879266156711091290000009290302438127036848_">
	<g>
		<text transform="matrix(1 0 0 1 87.6379 104.8561)" class="st2 st3">MOB-Cryptodira</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 102.0701 113.0548)" class="st2 st3">A-123</text>
	</g>
</g>
<g id="A-124">
	<polygon id="A124" class="st1" points="164.6,61.7 164.2,59.2 163,56 160.5,53.1 157,49.7 152.1,45.7 147.3,43.9 140.9,43.3 
		133.1,44 129.9,44.7 129.9,89.3 150.3,89.2 150.3,89.4 150.6,89.1 150.6,82.3 164.8,69.4 164.9,61.7 	"/>
</g>
<g id="A-128">
	<polygon id="A128" class="st1" points="150.6,82.3 150.6,89.1 150.3,89.4 150.3,124.6 165.2,124.6 169.4,121.8 169.4,113.8 
		181.9,113.8 181.9,107.9 175.2,101.2 182.3,94.1 196.9,108.4 198.4,106.9 200.6,106.9 200.8,77.4 195.6,77 191.8,61.7 164.9,61.7 
		164.8,69.4 	"/>
</g>
<g id="A-136">
	<polygon id="A136" class="st1" points="200.8,114.4 200.8,107.4 200.8,107.3 198.6,107.3 197,108.7 182.4,94.3 175.4,101.5 
		181.9,108.1 181.9,114.2 169.5,114.2 169.5,122 165.2,124.8 150.5,124.8 150.4,133.8 156,138.9 164.7,139.3 164.7,136.6 
		201.2,136.4 	"/>
</g>
<g id="A-150_1_">
	<g>
		<polygon class="st4" points="171.4,192.9 171.4,192.9 174.6,192.9 		"/>
		<polygon class="st4" points="164.2,214.3 164.1,214.3 164.2,221.4 		"/>
		<polygon class="st1" points="164.3,223.2 164.2,221.4 164.2,223.2 		"/>
		<polygon class="st1" points="174.6,192.9 201,192.9 200.8,146.1 156.1,145.9 150.2,150.6 150.3,164.1 150,164.1 150.3,192.9 
			171.4,192.9 171.4,192.9 		"/>
		<polygon class="st1" points="201,193.1 201,192.9 174.6,192.9 		"/>
	</g>
</g>
<g id="A-154">
	<polygon id="A154" class="st1" points="150,164.1 123.2,164.2 123.2,199.9 129.4,199.9 129.4,214.3 164.1,214.3 164.1,210 
		171.4,210 171.4,192.9 150.3,192.9 	"/>
</g>
<g id="A-160">
	<polygon id="A160" class="st1" points="129.4,199.7 123.2,199.7 123.2,164 102.5,164 102.5,237.9 109,236.2 118.2,234.1 
		126.1,232.2 148.8,226.8 164.2,223 164.2,221.2 164.1,214 129.4,214 	"/>
</g>
<g id="A-168">
	<polygon id="A168" class="st1" points="85.4,163.9 85.4,174.1 53.8,202.5 53.4,219 56.9,219 57.3,223.5 58.6,227.5 60.7,231.2 
		62.9,234 65.4,236 68.1,238.4 71.6,240.2 75,241.3 77.6,241.7 80.9,242.1 83.8,241.9 87.5,241.2 94.1,239.9 101.8,238 102.8,237.7 
		102.8,163.8 	"/>
</g>
<g id="A-178">
	<polygon id="A178" class="st1" points="85.2,174.3 85.2,164.1 75.7,164.1 75.7,151 70,145.7 23.5,145.7 23.4,154.9 28.9,154.7 
		28.9,167.7 23.4,167.8 23.5,223.5 28.9,223.5 29,219.1 53.2,219.1 53.6,202.7 	"/>
</g>
<g id="B-100">
	<polygon id="B100" class="st5" points="200.9,106.9 201,86.5 216.9,86.5 216.9,94.6 216.8,123.9 213.2,123.6 213.2,129.5 
		206.3,129.3 206.1,136.2 200.8,136.3 200.9,114 	"/>
</g>
<g id="B-101">
	<g>
		<polygon id="B101" class="st5" points="249.8,86.9 249.8,86.9 233.7,86.9 233.7,94.9 233.9,123.3 237.7,123.3 237.7,130 
			244.4,130.1 244.2,136.4 249.2,136.5 249.2,139.5 253.3,139.5 253.3,86.9 		"/>
	</g>
</g>
<g id="B-102">
	<polygon id="B102" class="st5" points="305.1,100 305.1,100 305.1,89.1 286.8,89.1 286.8,60.3 258.8,60.3 255.1,76.9 249.7,77 
		249.8,86.9 253.3,86.9 253.3,139.5 292.8,139.7 292.7,137 296.8,137.1 296.7,133.1 299.8,133.1 300,130.5 305.2,130.6 	"/>
</g>
<g id="B-108">
	<polygon id="B108" class="st5" points="305.1,89.1 305.1,100 321.3,100 321.3,44.9 320.3,44.4 317.4,43.4 310.8,43.3 305.7,44.2 
		300.5,45.6 295.6,48.5 292.3,51.3 290.1,54.8 287.8,58.5 286.8,60.3 286.8,60.3 286.8,89.1 	"/>
</g>
<g id="B-112">
	<polygon id="B112" class="st5" points="356.7,52.6 352.2,51.5 324.4,45.3 321.3,44.9 321.3,100 356.7,100 	"/>
</g>
<g id="B-124">
	<polygon id="B124" class="st5" points="422.3,61.7 422.2,66.8 400.5,66.8 400.5,63.2 356.7,52.6 356.7,100 372.5,100 372.5,132.4 
		379.7,139.7 401.2,139.8 401.5,125.1 409.9,124.8 409.9,127 427.5,126.9 427.5,61.6 	"/>
</g>
<g id="B-158">
	<g>
		<polygon id="B158" class="st5" points="407.9,183.6 427.1,183.6 427.1,159.4 407.5,159.7 407.7,151.5 400.9,151.3 400.9,145.3 
			379.5,145.5 372.1,152.8 372,170.7 407.9,170.7 		"/>
	</g>
</g>
<g id="B-170">
	<polygon id="B170" class="st5" points="407.9,183.6 407.9,170.7 372,170.7 372,186 327.2,186.2 327.2,234 333.2,235.5 340,237.2 
		346.8,238.5 351.7,239.9 357.7,241.2 362.4,242.3 366.9,242.8 369.7,243.2 371.2,243.2 373.6,243.1 377.8,242.1 381.2,240.3 
		384.5,237.7 386.8,235.5 389.2,233.1 391.1,230.3 392.6,227.1 393.4,222.5 393.9,219.9 422,219.9 422.1,224.7 427.1,224.6 
		427.1,183.6 	"/>
</g>
<g id="B-175">
	<polygon id="B175" class="st5" points="304.8,186.3 304.7,196.7 295.3,196.6 295.3,225.7 303.7,228.2 312,230.4 318.9,232.2 
		326.1,233.8 327.2,234 327.2,186.2 	"/>
</g>
<g id="B-180">
	<polygon id="B180" class="st5" points="299.7,156 299.6,152.4 296.2,152.4 296,149.1 291.9,148.9 291.8,146 235.4,146.1 
		235.4,190.7 297,190.7 297.2,175.2 304.6,175 304.5,156.4 	"/>
</g>
<g id="C-100">
	<polygon id="C100" class="st0" points="462,80 462,79.8 457.9,76.3 457.9,76.8 427.6,76.8 427.5,139.7 462,139.8 462,80.7 	"/>
</g>
<g id="C-110">
	<polygon id="C110" class="st0" points="480.2,109.5 480.2,109.5 480.2,80.4 478.9,80.4 462,80.4 462,139.5 480.2,139.6 	"/>
</g>
<g id="C-114">
	<polygon id="C114" class="st0" points="480.2,139.4 504.9,139.5 514.5,131.3 514.5,109.4 480.2,109.4 	"/>
</g>
<g id="C-116">
	<polygon id="C116" class="st0" points="510.2,82.5 510.2,79.5 496.8,79.5 496.9,80.7 480.6,80.7 480.6,109.8 514.9,109.8 
		514.9,82.5 	"/>
	<rect x="480.6" y="109.8" class="st4" width="36.7" height="31"/>
</g>
<g id="C-120">
	<polygon id="C120" class="st0" points="461.9,80.7 478.9,80.7 496.4,80.7 496.3,79.4 509.7,79.4 509.7,82.4 514.5,82.4 514.5,64.2 
		515.7,64.2 495.9,64.2 495.9,36 492.6,32.5 458.1,41.6 457.9,76.3 461.9,79.8 	"/>
</g>
<g id="C-126">
	<polygon id="C126" class="st0" points="526.8,34.7 526.9,31.8 523.3,31.5 523.1,29.5 492.8,29.5 492.7,32.3 492.7,32.3 496,35.9 
		496,64 515.8,64 521.5,63.9 528.9,57.9 529,34.5 	"/>
</g>
<g id="C-130">
	<g>
		<polygon id="C130" class="st0" points="594.2,68.8 620.1,68.8 620,61.3 615.2,61.2 605.6,28.9 555.1,29 555,32.1 552,31.7 
			551.5,34.2 550.2,34.4 550,57.1 556.8,63.9 564.5,63.9 564.5,83.7 578.5,83.7 		"/>
	</g>
</g>
<g id="C-138">
	<polygon id="C138" class="st0" points="573.9,100.3 621,100 620.9,83.5 617.3,83.5 617.3,80 620.2,79.8 620.1,68.8 594.2,68.8 
		578.5,83.7 564.5,83.7 564.5,91.9 	"/>
</g>
<g id="C-150">
	<polygon id="C150" class="st0" points="620.2,115.7 617.3,115.7 617.4,114.4 620.9,114.4 621,106.7 573.1,106.5 563.9,115.7 
		564.1,122.3 620.2,122.3 	"/>
</g>
<g id="C-154">
	<polygon id="C154" class="st0" points="593.9,149.2 569.4,127.1 564.3,127.1 564.4,132.9 564.8,146.9 558.4,146.9 558.4,152.2 
		565.1,159 565.1,159.1 565.2,159.3 565.2,177.6 565.2,177.7 565.2,177 565.2,177.7 567.7,177.7 567.7,175.6 571.7,175.8 
		571.7,177.6 585.7,177.6 585.7,175.9 585.6,174.4 593.9,172.3 604.7,169.6 613.2,167.7 620.1,166 620.1,156.4 620.1,149.2 	"/>
</g>
<g>
	<text transform="matrix(1 0 0 1 573.9702 165.1188)" class="st2 st6">Casa Sierra</text>
</g>
<g>
	<text transform="matrix(1 0 0 1 573.9041 159.9802)" class="st2 st6">C-154</text>
</g>
<g id="C-158">
	<path id="C158" class="st0" d="M558.1,152.5v-5.3l-18.8-0.2v37.3l6.1-1.5l2.8-0.7l6.4-1.6l3.3-0.9l0.8-0.2l5-1.3l0,0
		c0.1,0,0.2-0.1,0.2-0.1c0.2-0.2,0.4-0.2,0.5-0.1l0.5-0.2v-18.4L558.1,152.5z"/>
</g>
<g id="C-162">
	<polygon id="C162" class="st0" points="519.7,146.9 519.7,160 519.7,160 512.7,167 512.7,171.9 512.7,190.8 515.9,190 518.9,189.4 
		529.3,186.9 534.4,185.6 539.5,184.3 539.5,147 	"/>
</g>
<g id="C-166">
	<polygon id="C166" class="st0" points="512.7,171.9 512.7,167 519.7,160 519.7,146.9 495.7,146.8 495.7,171.9 	"/>
</g>
<g id="C-170">
	<polygon id="C170" class="st0" points="477.8,167.6 477.7,167.6 477.7,171.9 495.7,171.9 495.7,146.8 477.8,146.7 	"/>
</g>
<g id="C-172">
	<polygon id="C172" class="st0" points="477.7,146.6 477.5,146.6 477.6,146.6 460.4,146.7 460.3,157.5 465.9,157.4 465.6,167.4 
		469.7,167.4 469.7,167.5 477.7,167.4 	"/>
</g>
<g id="Layer_16">
	<path class="st0" d="M495.6,172.2h-18v-4.4l-7.9,0.1v10.3h3.5l1.3,4.7l1.5,2.7l1.8,2.4l1.3,1.5l1.8,1.6l2.3,1.5l1.6,1l2.1,1
		l2.4,0.5h2.7l3.6-0.8l5.4-0.9c0,0,4.1-0.9,4.7-1c0.6-0.2,5-1,5-1l1.7-0.4v-18.8L495.6,172.2L495.6,172.2z"/>
</g>
<path d="M201,113.8h-0.4v24.8h-44.6l-5-4.9v-45H75.1v45.2l-5.3,4.7H40.2c-3.6,0-6.4-1.1-8.3-2.3c-0.9-0.6-1.7-1.3-2.4-1.7
	c-0.5-0.5-0.9-0.9-1.2-1.1c-2.1-2-3.2-4.9-3.8-7.4c-0.3-1.3-0.5-2.4-0.5-3.1c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.2,0-0.3,0-0.3h-0.4h0.4
	V61.6h4.6v4.6h21.9v-2.7l83.1-19.1l-0.1-0.4l0.1,0.4c0,0,0.1,0,0.2,0c0.7-0.2,3.9-0.7,7.7-0.7c3.8,0,8.1,0.5,11,2.7
	c3.3,2.3,6.2,4.6,8.4,7.1c2.2,2.5,3.6,5.3,3.8,8.2v0.4h27.2l3.9,15.2l5,0.3v8.9v20.8v6.4L201,113.8l0.4,0v-6.4V86.5v-9.7l-5.2-0.3
	l-3.9-15.2h-27.5v0.4h0.4c-0.2-3.2-1.8-6-4.1-8.7c-2.3-2.6-5.3-5-8.6-7.3c-3.2-2.2-7.6-2.7-11.5-2.7c-4.4,0-8,0.8-8,0.8l0,0
	L49.6,62.8v2.5H29.3v-4.6h-6.2v60.9l0,0c0,0,0.1,2,0.6,4.6c0.6,2.5,1.8,5.6,4.1,7.8c0.2,0.2,0.5,0.6,1.2,1.1
	c1.8,1.6,5.6,4.2,11.2,4.2h29.9l5.8-5.1V89.4h74.4v44.6l5.5,5.3h45.7v-25.7C201.5,113.8,201,113.8,201,113.8z"/>
<polygon points="81.4,94.8 81.4,95.2 143.9,95.2 143.9,120.3 81.6,120.3 81.8,94.9 81.4,94.8 81.4,95.2 81.4,94.8 81,94.8 
	80.8,121.1 144.8,121.1 144.8,94.4 81,94.4 81,94.8 "/>
<polygon points="201,86.9 216.7,86.9 216.7,123.3 212.9,123.3 212.8,129 205.9,128.9 205.9,135.8 201.4,135.8 201.4,136.6 
	206.7,136.6 206.7,129.7 213.6,129.9 213.6,124 217.5,124 217.5,86.1 201,86.1 "/>
<polyline points="217.3,94.5 233.5,94.5 233.5,93.8 217.3,93.8 "/>
<path d="M318,44c0.3,0.1,1.2,0.2,2,0.5c0.9,0.2,0.9,0.3,3.7,1c0.6,0.1,2.6,0.6,5,1.1c8.6,1.8,26.6,6.1,42.4,9.9
	c7.9,1.9,15.3,3.7,20.6,5c2.7,0.6,4.9,1.2,6.4,1.6c1.5,0.4,2.4,0.5,2.4,0.5l0.1-0.4h-0.4v4h22.6v-5.1h4.6v77.3H380l-7-7V99.8h-68.3
	v30.3h-5.1v2.8h-3.1v3.8h-4.1v2.6h-42.8v-3.1h-4.9v-6.4h-6.7v-6.4h-4.1V87.1h-0.4v0.4v-0.4h-0.4v37h4.1v6.4h6.7v6.4h4.9v3.1h44.4
	v-2.6h4.1v-3.8h3.1v-2.8h5.1v-30.3h66.6v32.2l7.5,7.5h48.5V61.3h-6.2v5.1h-21v-3.5l-0.3-0.1c0,0-17.8-4.4-36.3-8.8
	c-9.3-2.2-18.7-4.5-26.1-6.1c-3.7-0.9-7-1.6-9.4-2.1c-2.5-0.5-4-0.9-4.8-1.1c-3-0.8-4.6-1.2-5.4-1.4l-0.2-0.1l-0.2,0.3l-0.4,0.3"/>
<path d="M318.5,43.4l-0.1-0.2l-0.2-0.1c0,0-1.6-0.5-4.6-0.5c-2.8,0-6.7,0.4-11.8,2c-5.3,1.7-8.6,4.8-10.8,7.8c-1.2,1.5-2,3-2.7,4.3
	s-1.3,2.4-1.8,3.1l0.3,0.2v-0.4h-28.2l-3.8,16.7l-5.5,0.2v9.7l-15.9,0v1l16.7,0v-9.8l5.3-0.2l3.8-16.6h27.9l0.2-0.2
	c0.5-0.8,1.1-2,1.8-3.2c2.1-3.9,5.6-9.3,13.1-11.8c4.9-1.6,8.8-2,11.5-2c1.5,0,2.6,0.2,3.3,0.2c0.4,0.1,0.6,0.1,0.9,0.2
	c0.1,0,0.2,0,0.2,0.1h0.1l0,0"/>
<path d="M318.5,43.5L318.5,43.5"/>
<path d="M573.2,107.2l47.4,0.2v6.6h-3.6v2.1h2.8v49.8l-34.4,8.2v3.2h-13.3v-1.8h-4.6v1.8h-1.7h-0.1c0,0-0.9,0.2-2.4,0.6
	c-10.7,2.8-54.8,14.1-69.9,16.6c-0.7,0.1-1.4,0.2-2.1,0.2c-4.8,0-9.1-2.7-12.2-6.1s-5.2-7.6-5.5-10.4l-0.1-0.3l-14.8-1v-9.3h-30.8
	V147l24.6,0.1v-0.8l-25.3-0.1v22.3h30.8v9.2l15.5,1v-0.4l-0.4,0.1c0.4,3,2.4,7.2,5.6,10.8c3.2,3.5,7.7,6.4,12.8,6.4
	c0.7,0,1.5-0.1,2.3-0.2c8.6-1.4,26.8-5.7,42.7-9.7c15.9-3.9,29.7-7.5,29.7-7.5l-0.1-0.4v0.4h2.4v-1.8h3.1v1.8h14.9v-3.5l34.4-8.2
	v-51.2h-2.8v-0.5h3.6v-8.2l-48.5-0.2l-9.2,9.2l0.6,31.1l-104.2-0.4v0.8l104.7,0.4l-0.5-31.5"/>
<polygon points="526.4,31.9 526.4,35.1 528.7,35.1 528.7,57.6 521.3,63.8 514.2,63.8 514.2,131.2 505,139.3 428,139.3 428,77.1 
	458.5,77.1 458.5,41.7 493.1,33 493.1,30 522.6,30 522.9,32 527.2,32.3 527.7,18.9 550.8,18.9 551.3,32.5 555.4,32.6 555.4,29.7 
	605.5,29.7 615,62 619.8,62 619.8,79.6 616.7,79.6 616.7,84 620.5,84 620.5,100 615.1,100 573.9,100 564.9,92 564.9,63.8 557,63.8 
	550.3,57.1 550.3,34.8 552.1,34.8 552.1,31.9 551.3,31.9 551.3,34 549.5,34 549.5,57.4 556.7,64.6 564.1,64.6 564.1,92.3 
	573.6,100.7 615.1,100.7 621.3,100.7 621.3,83.2 617.5,83.2 617.5,80.5 620.5,80.5 620.5,61.2 615.5,61.2 606.1,28.9 554.6,28.9 
	554.6,31.7 552.1,31.7 551.6,18.1 526.9,18.1 526.5,31.5 523.6,31.2 523.3,29.2 492.3,29.2 492.3,32.3 457.7,41 457.7,76.3 
	427.2,76.3 427.1,140.1 505.3,140.1 514.9,131.6 514.9,64.6 521.6,64.6 529.5,58 529.5,34.3 527.3,34.3 527.3,31.9 "/>
<polygon points="448.5,149.7 448.5,150.1 452.5,150.1 452.5,146.2 448.1,146.2 448.1,150.1 448.5,150.1 448.5,149.7 448.9,149.7 
	448.9,147 451.7,147 451.7,149.3 448.5,149.3 448.5,149.7 448.9,149.7 "/>
<polygon points="445,149.7 445,150.1 449,150.1 449,146.2 444.6,146.2 444.6,150.1 445,150.1 445,149.7 445.4,149.7 445.4,147 
	448.2,147 448.2,149.3 445,149.3 445,149.7 445.4,149.7 "/>
<polygon points="441.6,149.7 441.6,150.1 445.5,150.1 445.5,146.2 441.1,146.2 441.1,150.1 441.6,150.1 441.6,149.7 442,149.7 
	442,147 444.7,147 444.7,149.3 441.6,149.3 441.6,149.7 442,149.7 "/>
<polygon points="438,149.7 438,150.1 442,150.1 442,146.2 437.7,146.2 437.7,150.1 438,150.1 438,149.7 438.4,149.7 438.4,147 
	441.3,147 441.3,149.3 438,149.3 438,149.7 438.4,149.7 "/>
<polygon points="434.6,149.7 434.6,150.1 438.5,150.1 438.5,146.2 434.2,146.2 434.2,150.1 434.6,150.1 434.6,149.7 435,149.7 
	435,147 437.7,147 437.7,149.3 434.6,149.3 434.6,149.7 435,149.7 "/>
<polygon points="431.1,149.7 431.1,150.1 435.1,150.1 435.1,146.2 430.7,146.2 430.7,150.1 431.1,150.1 431.1,149.7 431.5,149.7 
	431.5,147 434.3,147 434.3,149.3 431.1,149.3 431.1,149.7 431.5,149.7 "/>
<polygon points="172.1,138.9 172.1,139.3 176.7,139.3 176.7,135.8 171.7,135.8 171.7,139.3 172.1,139.3 172.1,138.9 172.5,138.9 
	172.5,136.6 175.9,136.6 175.9,138.5 172.1,138.5 172.1,138.9 172.5,138.9 "/>
<polygon points="167.8,138.9 167.8,139.3 172.5,139.3 172.5,135.8 167.4,135.8 167.4,139.3 167.8,139.3 167.8,138.9 168.2,138.9 
	168.2,136.6 171.6,136.6 171.6,138.5 167.8,138.5 167.8,138.9 168.2,138.9 "/>
<polygon points="164.8,138.9 164.8,139.3 168.3,139.3 168.3,135.8 164.4,135.8 164.4,139.3 164.8,139.3 164.8,138.9 165.2,138.9 
	165.2,136.6 167.5,136.6 167.5,138.5 164.8,138.5 164.8,138.9 165.2,138.9 "/>
<polygon points="176.1,138.9 176.1,139.3 180.8,139.3 180.8,135.8 175.7,135.8 175.7,139.3 176.1,139.3 176.1,138.9 176.6,138.9 
	176.6,136.6 180,136.6 180,138.5 176.1,138.5 176.1,138.9 176.6,138.9 "/>
<polygon points="180.3,138.9 180.3,139.3 184.9,139.3 184.9,135.8 179.9,135.8 179.9,139.3 180.3,139.3 180.3,138.9 180.7,138.9 
	180.7,136.6 184.1,136.6 184.1,138.5 180.3,138.5 180.3,138.9 180.7,138.9 "/>
<polygon points="184.4,138.9 184.4,139.3 189,139.3 189,135.8 184,135.8 184,139.3 184.4,139.3 184.4,138.9 184.8,138.9 
	184.8,136.6 188.2,136.6 188.2,138.5 184.4,138.5 184.4,138.9 184.8,138.9 "/>
<polygon points="188.5,138.9 188.5,139.3 193.2,139.3 193.2,135.8 188.1,135.8 188.1,139.3 188.5,139.3 188.5,138.9 188.9,138.9 
	188.9,136.6 192.4,136.6 192.4,138.5 188.5,138.5 188.5,138.9 188.9,138.9 "/>
<polygon points="192.6,138.9 192.6,139.3 197.2,139.3 197.2,135.8 192.2,135.8 192.2,139.3 192.6,139.3 192.6,138.9 193,138.9 
	193,136.6 196.5,136.6 196.5,138.5 192.6,138.5 192.6,138.9 193,138.9 "/>
<polygon points="196.8,138.9 196.8,139.3 201.4,139.3 201.4,135.8 196.4,135.8 196.4,139.3 196.8,139.3 196.8,138.9 197.2,138.9 
	197.2,136.6 200.5,136.6 200.5,138.5 196.8,138.5 196.8,138.9 197.2,138.9 "/>
<polygon points="23.3,85.3 35.5,85.3 56.1,105.8 75.2,105.8 75.2,105.1 56.4,105.1 35.9,84.4 23.3,84.4 "/>
<polygon points="116.6,89.1 116.6,78.5 113.4,75 113.4,60.3 95.6,60.3 95.6,52.4 94.9,52.4 94.9,61.2 112.5,61.2 112.5,75.3 
	115.8,78.8 115.8,89.1 "/>
<polyline points="129.5,44.6 129.5,89.3 130.3,89.3 130.3,44.6 "/>
<polygon points="151,88.8 151,82.5 157.7,76.1 165.2,69.6 165.2,61.7 164.4,61.7 164.4,69.2 157.2,75.5 150.2,82.1 150.2,88.8 "/>
<polygon points="84.6,164.5 84.6,174.3 53,202.7 52.7,219.3 53.5,219.3 53.7,203.1 85.4,174.7 85.4,164.5 "/>
<polygon points="102.1,164.2 102.1,213.6 102.1,238.3 102.9,238.3 102.9,213.6 102.9,164.2 "/>
<polygon points="122.8,164.2 122.8,200.3 128.9,200.3 128.9,214.7 163.8,214.7 163.8,213.9 129.7,213.9 129.7,199.5 123.6,199.5 
	123.6,164.2 "/>
<polygon points="149.8,164.2 149.8,193.1 200.5,193.1 200.5,192.2 150.6,192.2 150.6,164.2 "/>
<polygon points="171,192.9 170.7,209.5 163.7,209.5 163.7,223.1 164.5,223.1 164.5,210.3 171.5,210.3 171.7,192.9 "/>
<polyline points="320.9,44 320.9,99.6 321.7,99.6 321.7,44 "/>
<polygon points="249.6,197 278.5,197 294.9,197 294.9,225.9 295.6,225.9 295.6,196.2 278.5,196.2 249.6,196.2 "/>
<polygon points="304.3,186.1 304.4,196.2 295.3,196.2 295.3,197 305.2,197 305.2,186.1 "/>
<polygon points="235,145.7 235,191 297.8,191 297.8,175 297,175 297,190.2 235.8,190.2 235.8,145.7 "/>
<polygon points="304.3,174.3 286.5,174.3 286.5,168.6 304.3,168.6 304.3,167.8 285.8,167.8 285.8,175.1 304.3,175.1 "/>
<polygon points="402.1,139.3 402.1,125.3 409.7,125.3 409.7,139.3 410.5,139.3 410.5,124.5 401.3,124.5 401.3,139.3 "/>
<polyline points="410.5,127.4 427.5,127.4 427.5,126.6 410.5,126.6 "/>
<polygon points="407.3,145.9 407.3,151.3 401.3,151.3 401.3,145.9 400.5,145.9 400.5,152.1 408.1,152.1 408.1,145.9 "/>
<polygon points="413.6,145.9 413.6,159 408.1,159 408.1,145.9 407.3,145.9 407.3,159.7 414.3,159.7 414.3,145.9 "/>
<polygon points="426.7,159.2 414.3,159.2 414.3,145.9 413.6,145.9 413.6,160 426.7,160 "/>
<polygon points="430.5,161.5 430.5,161.9 444.2,161.9 444.2,152.2 430.1,152.2 430.1,161.9 430.5,161.9 430.5,161.5 430.9,161.5 
	430.9,153 443.4,153 443.4,161.1 430.5,161.1 430.5,161.5 430.9,161.5 "/>
<polygon points="460.6,167.1 455.1,167.1 455.1,157 460.6,157 460.6,156.1 454.3,156.1 454.3,167.9 460.6,167.9 "/>
<polygon points="459.9,158.2 459.9,167.9 467.1,167.9 467.1,167.1 460.6,167.1 460.6,158.2 "/>
<polygon points="477.4,158.2 477.4,167.2 467.1,167.1 467.1,167.9 478.2,168 478.2,158.2 "/>
<polyline points="469.4,167.7 469.4,178.1 470.3,178.1 470.3,167.7 "/>
<polygon points="458,77 461.5,79.9 461.5,139.3 462.4,139.3 462.4,79.6 458.4,76.4 "/>
<polygon points="463.8,86 463.8,86.5 469.8,86.5 469.8,80.3 463.4,80.3 463.4,86.5 463.8,86.5 463.8,86 464.2,86 464.2,81 469,81 
	469,85.6 463.8,85.6 463.8,86 464.2,86 "/>
<polygon points="492.2,32.6 495.6,36.2 495.6,64.6 514.5,64.6 514.5,63.7 496.3,63.7 496.3,35.8 492.7,32 "/>
<polygon points="490.9,86.1 490.9,86.5 496.4,86.5 496.4,80.3 490.5,80.3 490.5,86.5 490.9,86.5 490.9,86.1 491.3,86.1 491.3,81 
	495.6,81 495.6,85.6 490.9,85.6 490.9,86.1 491.3,86.1 "/>
<polygon points="496.4,85.6 496.4,86 510.1,86 510.1,78.9 496,78.9 496,86 496.4,86 496.4,85.6 496.8,85.6 496.8,79.7 509.3,79.7 
	509.3,85.1 496.4,85.1 496.4,85.6 496.8,85.6 "/>
<polygon points="509.8,85.6 509.8,86 514.5,86 514.5,81.9 509.4,81.9 509.4,86 509.8,86 509.8,85.6 510.2,85.6 510.2,82.7 
	513.7,82.7 513.7,85.1 509.8,85.1 509.8,85.6 510.2,85.6 "/>
<polyline points="499.2,79.7 499.2,85.8 500,85.8 500,79.7 "/>
<polyline points="500.9,79.7 500.9,85.8 501.7,85.8 501.7,79.7 "/>
<polyline points="502.5,79.7 502.5,85.8 503.3,85.8 503.3,79.7 "/>
<polyline points="504.1,79.7 504.1,85.8 505,85.8 505,79.7 "/>
<polyline points="462,81 495.9,81 495.9,80.3 462,80.3 "/>
<polyline points="505.8,79.7 505.8,85.8 506.5,85.8 506.5,79.7 "/>
<polyline points="495.3,147 495.3,194.4 496.1,194.4 496.1,147 "/>
<polygon points="519.5,147.3 519.5,159.7 512.4,166.7 512.4,190.7 513.2,190.7 513.2,167.1 520.4,160 520.4,147.3 "/>
<polyline points="512.7,171.5 478,171.5 478,172.4 512.7,172.4 "/>
<polyline points="539.2,147.3 539.2,184.9 540,184.6 540,147.3 "/>
<polygon points="495.7,177.8 486,177.8 485.9,194.2 486.8,194.2 486.8,178.7 495.7,178.7 "/>
<polygon points="557.9,147 557.9,152.5 564.7,159.3 564.7,178.3 565.5,178.3 565.5,159 558.7,152.2 558.7,147 "/>
<polygon points="564.4,127.7 569.3,127.7 593.8,149.8 620,149.8 620,148.9 594.1,148.9 569.6,126.9 564.4,126.9 "/>
<polygon points="606.1,106.8 606.1,114.9 617.3,114.9 617.3,114 606.9,114 606.9,106.8 "/>
<polygon points="564.6,83.9 578.7,84.1 594.3,69.2 619.8,69.2 619.8,68.4 594,68.4 578.4,83.3 564.6,83.1 "/>
<polygon points="593.1,100 593.1,91.8 599,91.8 599,91 592.3,91 592.3,100 "/>
<polygon points="599.4,100 599.4,84.1 620.7,84.1 620.7,83.3 598.7,83.3 598.7,100 "/>
<polyline points="613.1,83.7 613.1,100.3 613.9,100.3 613.9,83.7 "/>
<polyline points="606.7,100.2 606.7,83.7 605.9,83.7 605.9,100.2 "/>
<polyline points="609.2,107.2 609.2,114.2 610,114.2 610,107.2 "/>
<polyline points="611,107.2 611,114.2 611.8,114.2 611.8,107.2 "/>
<polyline points="612.9,107.2 612.9,114.2 613.6,114.2 613.6,107.2 "/>
<polyline points="614.7,107.2 614.7,114.2 615.5,114.2 615.5,107.2 "/>
<polyline points="616.6,107.2 616.6,114.2 617.4,114.2 617.4,107.2 "/>
<polyline points="618.4,107.2 618.4,114.2 619.3,114.2 619.3,107.2 "/>
<polygon points="200.8,106.6 198.1,106.6 196.7,108 182.2,93.5 174.5,101.2 181.3,108.1 181.3,113.8 181.3,113.9 181.7,114 
	181.7,113.5 168.9,113.5 168.9,121.7 165,124.3 150.9,124.3 150.9,125.1 165.2,125.1 169.7,122.1 169.7,114.4 182.1,114.4 
	182.1,113.8 182.2,107.8 175.6,101.2 182.2,94.7 196.7,109.2 198.4,107.4 200.8,107.4 "/>
<polyline points="477.4,147 477.4,158.2 478.2,158.2 478.2,147 "/>
<polyline points="477.4,167.6 477.4,172.3 478.2,172.3 478.2,167.6 "/>
<line class="st4" x1="481.6" y1="146.4" x2="485.3" y2="147.6"/>
<line class="st4" x1="466.1" y1="146.4" x2="469.7" y2="147.6"/>
<line class="st4" x1="509.4" y1="146.4" x2="505.8" y2="147.6"/>
<line class="st4" x1="501.8" y1="146.4" x2="505.4" y2="147.6"/>
<path d="M525,147.2c0-0.2,0-0.2,0-0.4l-0.1-0.2l-0.2,0.1c0,0.2,0,0.2,0,0.4l0.1,0.2L525,147.2z"/>
<line class="st4" x1="548.8" y1="146.6" x2="552.4" y2="147.8"/>
<line class="st4" x1="564" y1="131.3" x2="565.2" y2="135"/>
<line class="st4" x1="566.8" y1="112.5" x2="569.8" y2="110.9"/>
<path class="st7" d="M574.3,109.9"/>
<line class="st4" x1="571.7" y1="107.6" x2="570.1" y2="110.6"/>
<line class="st4" x1="566.3" y1="94.2" x2="569.4" y2="95.6"/>
<path d="M568.8,96.3L568.8,96.3v0.2h0.2l0,0v-0.2L568.8,96.3z"/>
<line class="st4" x1="571.5" y1="98.8" x2="569.7" y2="95.8"/>
<path class="st4" d="M451.6,139.5"/>
<path class="st4" d="M455.3,140.6"/>
<line class="st4" x1="465.1" y1="140.7" x2="468.7" y2="139.6"/>
<line class="st4" x1="507.8" y1="138.5" x2="509.4" y2="135.4"/>
<line class="st4" x1="512.7" y1="133.6" x2="509.7" y2="135.2"/>
<path d="M509.7,135.5c0.1,0.1,0.2,0.2,0.2,0.3h0.2v-0.2c-0.1-0.1-0.2-0.2-0.2-0.3h-0.2V135.5z M509.1,133.9c0,0.2,0,0.2,0.1,0.4
	l0.2,0.1l0.1-0.2c0-0.2-0.1-0.2-0.1-0.4l-0.2-0.1L509.1,133.9z"/>
<line class="st4" x1="551.2" y1="58.9" x2="554.2" y2="60.5"/>
<line class="st4" x1="218.7" y1="196.2" x2="215.3" y2="197.2"/>
<path d="M215.3,196.8c0-0.1,0-0.2,0-0.4l-0.1-0.1l-0.1,0.1c0,0.2,0,0.2,0,0.4H215.3L215.3,196.8z"/>
<line class="st4" x1="224.7" y1="196.3" x2="228.1" y2="197.4"/>
<line class="st4" x1="334.7" y1="100.6" x2="338.3" y2="99.4"/>
<path d="M338.3,100c0,0.2,0,0.2,0,0.4l0.2,0.1l0.1-0.2c0-0.2,0-0.3,0-0.4l-0.2-0.1L338.3,100z"/>
<path d="M338.5,100c0,0.2,0,0.2,0,0.4l0.1,0.2l0.2-0.1c0-0.2,0-0.2,0-0.4l-0.1-0.2L338.5,100z"/>
<path d="M375.6,148.8L375.6,148.8h-0.2v0.2l0,0h0.2L375.6,148.8z"/>
<line class="st4" x1="373.1" y1="151.3" x2="376.1" y2="149.8"/>
<polygon points="94.9,60.6 94.9,82.5 105.1,82.5 105.1,88.7 105.8,88.7 105.8,81.8 95.6,81.8 95.6,60.6 "/>
<line class="st4" x1="132.2" y1="94.5" x2="128.6" y2="95.6"/>
<line class="st4" x1="97.4" y1="163.8" x2="93.8" y2="165"/>
<line class="st4" x1="156.3" y1="146.1" x2="154.2" y2="149.3"/>
<line class="st4" x1="150.4" y1="151.1" x2="153.9" y2="149.6"/>
<path class="st4" d="M74.6,96.9"/>
<path class="st4" d="M75.8,100.5"/>
<path class="st4" d="M74.6,111.3"/>
<path class="st4" d="M75.8,114.9"/>
<path class="st4" d="M74.6,110.9"/>
<path class="st4" d="M75.8,107.2"/>
<path class="st4" d="M74.6,128.3"/>
<path class="st4" d="M75.8,132"/>
<path class="st4" d="M74.6,127.9"/>
<path class="st4" d="M75.8,124.3"/>
<path class="st4" d="M82.2,108"/>
<path class="st4" d="M81,104.4"/>
<path class="st4" d="M82.2,108.4"/>
<path class="st4" d="M81,112"/>
<path d="M151,111.3c-0.2,0-0.2,0-0.4,0l-0.1,0.2l0.2,0.1c0.2,0,0.3,0,0.4,0l0.1-0.2L151,111.3z"/>
<line class="st4" x1="150.6" y1="134.1" x2="153.6" y2="135.7"/>
<path d="M153,136.4L153,136.4v0.2h0.2l0,0v-0.2L153,136.4z"/>
<path class="st4" d="M91.6,88.3"/>
<path class="st4" d="M87.9,89.4"/>
<path class="st4" d="M92,88.3"/>
<path class="st4" d="M95.6,89.4"/>
<path class="st4" d="M46.6,138.1"/>
<path class="st4" d="M42.9,139.2"/>
<path class="st4" d="M47,138.1"/>
<path class="st4" d="M50.6,139.2"/>
<path class="st4" d="M117.9,119.7"/>
<path class="st4" d="M114.2,120.9"/>
<path d="M123.3,88.8c0,0.2,0,0.2,0,0.4l0.1,0.2l0.2-0.1c0-0.2,0-0.2,0-0.4l-0.1-0.2L123.3,88.8z"/>
<line class="st4" x1="137.3" y1="89.4" x2="141" y2="88.3"/>
<path d="M141,88.8c0,0.2,0,0.2,0,0.4l0.2,0.1l0.1-0.2c0-0.2,0-0.3,0-0.4l-0.2-0.1L141,88.8z"/>
<path d="M141.1,88.8c0,0.2,0,0.2,0,0.4l0.1,0.2l0.2-0.1c0-0.2,0-0.2,0-0.4l-0.1-0.2L141.1,88.8z"/>
<path d="M221.4,94.1c0,0.1,0,0.2,0,0.3l0.1,0.1l0.1-0.1c0-0.1,0-0.2,0-0.3H221.4L221.4,94.1z"/>
<line class="st4" x1="75.5" y1="89.1" x2="50" y2="65.7"/>
<polyline points="75.8,88.8 50.3,65.4 49.7,66 75.2,89.4 "/>
<text transform="matrix(1 0 0 1 183.425 53.8715)" class="st2 st3">Holland &amp; Sherry Interiors</text>
<g id="Layer_7">
</g>
<polyline class="st8" points="79.2,89.1 68.5,79.1 66.4,81.1 65.2,82.4 75.7,92.3 "/>
<polygon points="79.5,88.7 68.5,78.5 66.1,80.8 64.6,82.4 75.4,92.7 75.9,92.1 65.7,82.3 66.6,81.4 68.5,79.6 78.9,89.4 "/>
<polyline class="st8" points="76.1,163.7 67.3,171.6 65.2,169.6 63.8,168.1 75.9,157.3 "/>
<polygon points="75.6,163.4 67,171 65.2,169.3 64.1,168.2 75.9,157.6 75.3,157.1 62.9,168.1 64.6,169.9 67,172.1 76.1,164.1 "/>
<polyline points="75.3,116.5 23.7,116.5 23.7,117.3 75.3,117.3 "/>
<polygon points="286.5,60.2 286.5,89.5 304.7,89.5 304.7,101.7 305.4,101.7 305.4,88.7 287.2,88.7 287.2,60.2 "/>
<polyline points="326.8,185.8 326.8,234.3 327.6,234.3 327.6,185.8 "/>
<polyline points="356.3,52.8 356.3,99.7 357.1,99.7 357.1,52.8 "/>
<path d="M376.1,135.7c-0.1,0.1-0.2,0.2-0.2,0.3v0.2h0.2c0.1-0.1,0.2-0.2,0.2-0.3v-0.2H376.1z"/>
<path d="M314.2,186.3c0-0.2,0-0.2,0-0.4l-0.2-0.1l-0.1,0.2c0,0.2,0,0.3,0,0.4l0.2,0.1L314.2,186.3z"/>
<path d="M314,186.3c0-0.2,0-0.2,0-0.4l-0.1-0.2l-0.2,0.1c0,0.2,0,0.2,0,0.4l0.1,0.2L314,186.3z"/>
<polygon points="459.9,146.3 459.9,158.3 465.3,158.3 465.3,167.7 466.1,167.7 466.1,157.5 460.6,157.5 460.6,146.3 "/>
<polygon points="253.8,140 253.8,86.5 249.8,86.5 249.8,87.3 253,87.3 253,140 "/>
<line class="st4" x1="244.8" y1="136.8" x2="246.7" y2="136.2"/>
<polygon points="249.7,87.4 252.8,87.4 252.8,139.2 253.6,139.2 253.6,86.6 249.7,86.6 "/>
<path d="M246.5,136.7L246.5,136.7c0.1-0.2,0.1-0.2,0.1-0.3l-0.1-0.1l-0.1,0.1"/>
<polyline points="244.3,136.8 244.3,134.3 244,134.3 244,136.8 "/>
<line class="st4" x1="248.8" y1="136.7" x2="246.7" y2="136"/>
<polygon points="207.6,99.2 208.5,100.7 210.3,100.8 209.2,102.1 209.6,103.8 208,103.1 206.6,104 206.7,102.4 205.4,101.3 
	207,100.9 "/>
<line class="st9" x1="480.2" y1="80.3" x2="480.2" y2="139.3"/>
<line class="st9" x1="480.2" y1="109.8" x2="514.5" y2="109.8"/>
<line class="st9" x1="426.9" y1="183.6" x2="407.9" y2="183.6"/>
<line class="st9" x1="407.9" y1="183.9" x2="407.9" y2="170.3"/>
<line class="st9" x1="407.9" y1="170.7" x2="371.9" y2="170.7"/>
<polyline class="st4" points="402,172.7 394.4,172.7 394.4,173 "/>
<g>
	<polygon class="st4" points="495.7,171.9 512.7,171.9 512.7,167 519.7,160 519.7,146.9 495.7,146.8 	"/>
	<polygon class="st4" points="477.8,146.7 477.8,167.6 477.7,167.6 477.7,171.9 495.7,171.9 495.7,146.8 	"/>
	<polygon class="st4" points="477.7,146.7 477.7,146.8 460.6,146.9 460.4,157.6 466,157.6 465.7,167.5 469.7,167.6 469.7,167.7 
		477.7,167.6 477.7,146.7 	"/>
	<path class="st4" d="M477.7,146.7L477.7,146.7L477.7,146.7z"/>
	<polygon class="st4" points="465.7,167.5 466,157.6 460.4,157.6 460.6,146.9 477.7,146.8 477.7,146.7 460.2,146.7 460.2,167.7 
		469.7,167.7 469.7,167.6 	"/>
	<rect x="477.7" y="146.7" class="st4" width="0.1" height="20.9"/>
	<rect x="477.7" y="146.6" class="st4" width="0" height="0.1"/>
	<polygon class="st4" points="519.7,146.8 495.7,146.8 519.7,146.9 519.7,146.9 	"/>
	<polygon class="st4" points="519.7,146.9 519.7,146.9 539.5,147 539.5,146.9 	"/>
	<polygon class="st4" points="512.7,190.8 512.7,190.9 518.9,189.4 515.9,190 	"/>
	<polygon class="st4" points="519.7,160 519.7,160 512.7,167 	"/>
	<polygon class="st4" points="534.4,185.6 529.3,186.9 539.5,184.5 539.5,184.3 	"/>
	<polygon class="st4" points="558.4,146.9 539.5,147 558.4,147 	"/>
	<polygon class="st4" points="539.5,184.3 539.5,184.5 548.4,182.1 545.6,182.8 	"/>
</g>
<g>
	<polygon class="st4" points="564.4,133 564.5,147 558.4,147 564.8,147 	"/>
	<polygon class="st4" points="593.9,172.4 585.6,174.5 585.7,176.1 585.7,174.6 613.2,167.8 604.7,169.7 	"/>
	<polygon class="st4" points="571.7,175.9 567.7,175.8 567.7,177.9 565.2,177.9 565.2,177.7 565.2,159.4 565.1,159.2 565.1,177.9 
		567.9,177.9 567.9,175.9 571.7,175.9 571.7,177.9 585.7,177.9 585.7,177.7 571.7,177.7 	"/>
</g>
<path class="st8" d="M480.2,78.4"/>
<text transform="matrix(1 0 0 1 529.421 234.4978)" class="st2 st10">DCOTA 1st Floor</text>
<text transform="matrix(1 0 0 1 517.8849 171.7009)" class="st2 st3">ROMO</text>
<text transform="matrix(1 0 0 1 520.3509 179.2163)" class="st2 st3">C-162</text>
<text transform="matrix(1 0 0 1 499.1191 153.7155)" class="st2 st6">Sherle</text>
<text transform="matrix(1 0 0 1 497.4473 158.947)" class="st2 st6">Wagner</text>
<text transform="matrix(1 0 0 1 497.754 165.6687)" class="st2 st6">C-166</text>
<text transform="matrix(1 0 0 1 491.2823 130.5109)" class="st2 st3">C-114</text>
<text transform="matrix(1 0 0 1 489.0147 124.0822)" class="st2 st3">Gloster</text>
<text transform="matrix(1 0 0 1 580.0853 119.965)" class="st2 st3">C-150</text>
<text transform="matrix(1 0 0 1 573.8177 113.5363)" class="st2 st3">Madeval</text>
<text transform="matrix(1 0 0 1 489.7636 107.1584)" class="st2 st3">C-116</text>
<text transform="matrix(1 0 0 1 485.9219 93.9289)" class="st2 st3">The Rug</text>
<text transform="matrix(1 0 0 1 483.4003 100.3577)" class="st2 st3">Company</text>
<text transform="matrix(1 0 0 1 567.791 89.6356)" class="st2 st11">Poggenpohl</text>
<text transform="matrix(1 0 0 1 593.3925 79.0983)" class="st2 st3">C-138</text>
<text transform="matrix(1 0 0 1 555.7725 46.903)" class="st2 st3">David Sutherland</text>
<text transform="matrix(1 0 0 1 572.6935 55.7409)" class="st2 st3">C-130</text>
<text transform="matrix(1 0 0 1 500.4083 41.093)" class="st2 st3">HOLLY</text>
<text transform="matrix(1 0 0 1 501.2031 48.3254)" class="st2 st3">HUNT</text>
<text transform="matrix(1 0 0 1 502.3682 55.5569)" class="st2 st3">C-126</text>
<text transform="matrix(1 0 0 1 463.084 59.4455)" class="st2 st3">DEDON</text>
<text transform="matrix(1 0 0 1 465.7042 68.1237)" class="st2 st3">C-120</text>
<text transform="matrix(1 0 0 1 373.4189 160.821)" class="st2 st12">Schumacher</text>
<text transform="matrix(1 0 0 1 373.4189 166.4997)" class="st2 st13">Patterson Flynn Martin</text>
<text transform="matrix(1 0 0 1 410.1045 175.2663)" class="st2 st6">B-158</text>
<text transform="matrix(1 0 0 1 323.5605 69.4123)" class="st2 st3">FLORENSE</text>
<text transform="matrix(1 0 0 1 331.083 78.0905)" class="st2 st3">B-112</text>
<text transform="matrix(1 0 0 1 291.0459 69.4109)" class="st2 st3">Crestron</text>
<text transform="matrix(1 0 0 1 294.3882 78.09)" class="st2 st3">B-108</text>
<text transform="matrix(1 0 0 1 235.6821 103.739)" class="st2 st3">Pierre</text>
<text transform="matrix(1 0 0 1 235.6821 110.4171)" class="st2 st3">Frey</text>
<text transform="matrix(1 0 0 1 235.6821 119.0954)" class="st2 st3">B-101</text>
<text transform="matrix(1 0 0 1 201.7315 93.4377)" class="st2 st14">B-100</text>
<text transform="matrix(1 0 0 1 428.354 95.4509)" class="st2 st12">John Rosselli</text>
<text transform="matrix(1 0 0 1 429.923 101.6834)" class="st2 st12">&amp; Associates</text>
<text transform="matrix(1 0 0 1 433.923 108.9153)" class="st2 st12">C-100</text>
<g>
	<text transform="matrix(1 0 0 1 131.2021 56.966)" class="st2 st3">Resource</text>
</g>
<g>
	<text transform="matrix(1 0 0 1 133.7156 63.3643)" class="st2 st3">Center</text>
</g>
<text transform="matrix(1 0 0 1 107.794 222.4577)" class="st2 st3">DCOTA Select</text>
<text transform="matrix(1 0 0 1 107.794 229.9577)" class="st2 st3">A-160</text>
<text transform="matrix(1 0 0 1 307.6373 197.8894)" class="st2 st3">B-175</text>
<text transform="matrix(1 0 0 1 300.6279 205.5134)" class="st2 st3">DCOTA</text>
<text transform="matrix(1 0 0 1 300.6279 213.0134)" class="st2 st3">CAY</text>
<text transform="matrix(1 0 0 1 209.9463 215.157)" class="st2 st3">NORTH DOCK</text>
<text transform="matrix(1 0 0 1 24.2379 144.4557)" class="st2 st3">HOTEL ENTRANCE</text>
<text transform="matrix(1 0 0 1 198.9263 69.9069)" class="st2 st15">NORTH ENTRANCE</text>
<text transform="matrix(1 0 0 1 512.38 15.4884)" class="st2 st3">SOUTH ENTRANCE</text>
<text transform="matrix(0 -1 1 0 469.4286 125.3389)" class="st2 st3">Quadrille</text>
<text transform="matrix(0 -1 1 0 476.662 116.75)" class="st2 st3">C-110</text>
<text transform="matrix(1 0 0 1 431.9922 186.7917)" class="st2 st3">SOUTH DOCK</text>
<text transform="matrix(1 0 0 1 210.5849 136.8337)" class="st2 st3">Concierge</text>
<text transform="matrix(1 0 0 1 524.6134 87.5993)" class="st2 st3">C BAR</text>
<g id="Layer_22">
</g>
<g id="Layer_37">
</g>
<g id="Layer_41">
</g>
<g id="Layer_42">
</g>
<g id="Layer_45">
	<g>
		<polygon class="st4" points="75.8,89.4 66.6,81 65.3,82.3 75.2,91.6 75.2,89.4 		"/>
		<polygon class="st4" points="79.5,89.4 68.5,78.9 66.6,81 75.8,89.4 		"/>
		<polygon class="st4" points="79.5,89.4 75.8,89.4 77.7,91.1 75.8,89.4 75.2,89.4 75.2,91.6 79.4,95.6 82.5,92.3 		"/>
	</g>
</g>
<g>
	<polygon points="23.5,146.4 23.9,146.4 23.9,146 23.5,146 23.5,146 	"/>
	<path d="M23.1,146L23.1,146L23.1,146z"/>
	<path d="M86.9,241.3c-2.1,0.5-4.2,0.7-6,0.7c-5.8,0-10.4-2-13.9-4.4c-3.5-2.4-5.7-5.3-6.8-7.1c-2.7-4.2-3.1-8.2-3.1-10.1
		c0-0.3,0-0.6,0-0.8c0-0.1,0-0.2,0-0.2v-0.1l0.1-0.5H28.5v4.2h-4.7v-54.8h5.6v-13.7h-5.4v-8.2h-0.4V146h-0.4v9.4h5.4v12.1h-5.6v56.4
		h6.4v-4.3h27c0-0.3,0-0.5,0-0.5l0.4,0.1v0.4h-0.5c0,0.2,0,0.4,0,0.7c0,2,0.4,6.1,3.2,10.6c1.2,1.8,3.5,4.7,7.1,7.2
		c3.5,2.5,8.3,4.6,14.4,4.6c2,0,4-0.2,6.2-0.7c12.6-2.8,60.3-14.3,77.5-18.4v-0.8C147.4,226.9,99.6,238.4,86.9,241.3z"/>
	<path d="M56.7,219.2l-0.4-0.1c0,0,0,0.2,0,0.5h0.5C56.7,219.6,56.7,219.2,56.7,219.2z"/>
	<polygon points="23.1,146 23.5,146 23.5,146 	"/>
</g>
<g>
	<polygon points="201.3,145.4 156,145.4 149.9,150.2 149.9,163.7 75.9,163.7 75.9,150.9 70.1,145.6 23.1,145.6 23.1,146 23.5,146 
		23.9,146 23.9,146.4 69.7,146.4 75.1,151.3 75.1,164.5 150.8,164.5 150.8,150.6 156.2,146.2 200.5,146.2 200.5,196.9 201.3,196.9 	
		"/>
</g>
<g>
	<rect x="200.9" y="145.9" width="0.4" height="0.4"/>
	<path d="M393.7,219.8v0.4h0.4c0-0.2,0-0.4,0-0.4H393.7z"/>
	<g>
		<path class="st4" d="M393.7,219.8v0.4h0.4c0-0.2,0-0.4,0-0.4H393.7z"/>
		<path d="M372.4,186.3v-33.3l7.2-7.2h47.2v78.5h-4.3v-4.9h-29v0.4c0,0,0,0.2,0,0.3c-0.1,0.8-0.2,2.9-0.8,5.3c-0.5,2.4-1.6,5-3.2,7
			c-1.8,2.1-4.2,4.8-7.3,6.8c-3.1,2.1-6.9,3.6-11.6,3.6c-0.7,0-1.3,0-2.1-0.1c-2.7-0.2-9.6-1.7-18.4-3.8c-13.3-3.1-30.8-7.5-45-11.1
			c-3.6-0.9-7.1-1.8-10.1-2.6v0.9c7.1,1.8,15.9,4.1,25,6.4c10.4,2.6,21.2,5.3,30,7.3c8.9,2,15.7,3.5,18.4,3.8
			c0.7,0.1,1.5,0.1,2.2,0.1c4.9,0,8.9-1.6,12-3.8c3.2-2.1,5.6-4.9,7.5-7c2-2.4,3.1-5.6,3.6-8.3c0.4-2,0.5-3.6,0.6-4.4h-0.4v-0.4h0.4
			c0,0,0,0.2,0,0.4h27.5v4.9h6v-80h-48.3l-7.7,7.7v32.9h-66.4v-30.1h-4.9v-3.8h-3.6v-3.3h-4.1v-3.1h-92.3v0.4h0.4l0,0h0.4v0.4h90.5
			v3h4.1v3.3h3.6v3.8h4.9v30"/>
	</g>
	<polygon points="249.7,196.2 201.5,196.2 201.5,146.3 201.1,146.3 201.1,145.9 200.7,145.9 200.7,197 249.7,197 	"/>
</g>
<g id="_x34_7_x5F_cutlery">
	<path id="XMLID_866_" class="st16" d="M325.5,172.5c1.5-1.3,2.4-3.5,1.6-4.5c-0.9-0.9-3.2-0.3-4.6,1c-1.3,1.2-1.9,2.7-1.3,3.7
		c-1.7,2.1-4.8,4.1-5.8,4.9c-5,3.4-4.1,4.4-4.1,4.4s0.8,0.9,4.8-3.6c0.9-0.9,3.4-3.7,5.6-5.1C322.5,174,324.1,173.7,325.5,172.5z
		 M322.5,172c0.9,0.4,2.3,0.1,3.5-0.9c0.5-0.4,0.9-0.9,1.2-1.5c-0.2,0.9-0.9,2-1.6,2.7c-1.4,1.2-3.3,1.1-3.8,0.5
		c-0.3-0.2-0.4-1.2-0.2-2C321.5,171,322,171.8,322.5,172z M315.9,178.3c-4.1,4.6-4.4,3.5-4.4,3.5s1.4-0.5,4.6-4
		c2.7-2.6,4.5-4.2,5.3-4.8c-0.3-0.4-0.5-1.6,0.2-2.5c-0.5,1.6-0.1,2.1,0.2,2.4c0.4,0.4,1.7,0.6,2.5,0.4c-0.5,0.4-2,0.5-2.6-0.1
		C319.3,174.7,317.1,177,315.9,178.3z"/>
	<path id="XMLID_862_" class="st16" d="M307.9,167.4L307.9,167.4l3.6,3.5l-0.2,0.2l-3.9-3.1l-0.1,0.1l3.7,3.5l-0.2,0.2l-3.9-3.1
		l-0.1,0.1l3.7,3.5l-0.2,0.2l-3.9-3.1l-0.1,0.1l3.8,3.8l0,0c1.1,0.9,2,1.2,2.6,0.9c2.2,1.4,4.5,4.1,5.4,4.9c3.9,4.4,4.8,3.5,4.8,3.5
		s0.8-0.9-4.2-4.2c-0.9-0.8-3.8-2.7-5.6-4.7c0.3-0.7,0.1-1.4-1.3-2.5l0,0L307.9,167.4z M311.6,170.6L311.6,170.6L311.6,170.6z
		 M313.1,173.1c1.7,2,4.4,4,5.7,4.9c4.9,3.3,3.9,3.8,3.9,3.8s-0.6-1.3-4.4-3.8c-3.1-2.5-4.9-4.1-5.3-4.6c0,0-0.5,0.5-0.9,0.2
		l0.8-0.9c0.2-0.2-0.4-1.3-1.1-1.9C312.2,171,313.5,172.1,313.1,173.1z"/>
</g>
<g id="_x30_7_x5F_tea">
	<path id="XMLID_253_" class="st16" d="M540.8,100.9c-0.3-0.1-0.7,0.1-1,0.2c-0.1,0.4-0.1,0.8-0.2,1.3c0.5-0.4,0.9-0.5,1.3-0.5
		c0.9,0.2,0.8,1,0.6,1.6s-0.5,0.9-1.3,1.6c-0.3,0.3-1,0.6-1.6,0.9c-0.2,0.4-0.4,0.8-0.5,1.1c1.1-0.4,1.6-0.7,2.1-1
		c0.9-0.5,2.1-1.5,2.3-2.9C542.3,101.3,541.6,101.1,540.8,100.9z"/>
	<path id="XMLID_254_" class="st16" d="M533.7,92c0,0-1.5,1.2-0.2,3.5c0.9,1.9-0.4,2-0.4,2.4c0.6-0.4,2.8-0.7,0.9-2.6
		C532.8,93.8,533.7,92,533.7,92z"/>
	<path id="XMLID_252_" class="st16" d="M533.7,109.7c-1.4,0-3.8-0.1-6.1-0.5c1,0.6,2.2,1.1,3.3,1.3c0.9,0.1,2.1,0.1,3,0.1
		c0.9,0,2,0,2.7-0.1c0.6-0.1,2-0.5,3.3-1.3C537.4,109.5,535.1,109.7,533.7,109.7z"/>
	<path id="XMLID_1052_" class="st16" d="M533.6,98.6c-3.1,0-5.6,0.2-5.6,0.4s2.5,0.5,5.6,0.5c2.2,0,4.2-0.1,5.1-0.2
		c-0.1,0.2,0,0.5-1.1,0.5c-1.1,0.1-2.4,0.2-4,0.2c-1,0-2.7-0.2-2.9-0.2c0,1.6,0.3,5.9,0.9,7.6c-0.7-0.1-2.5-3.3-2.5-7.6
		c-0.5-0.1-0.9-0.2-1.3-0.3c0,1.1,0.5,6.5,2.4,8.2c0.9,0.2,1.5,0.4,3.3,0.3c1.6,0,2.4-0.1,3.2-0.3c2.2-1.6,2.4-8.4,2.4-8.7
		C539.1,98.6,536.7,98.6,533.6,98.6z"/>
	<path id="XMLID_249_" class="st16" d="M543.3,106.9c0-0.5-0.5-0.8-1.9-1l-0.3,0.3c0,0,1.7,0.4,1.7,0.7c0,0.2-3.1,1.6-8.9,1.6
		c-4.8,0-9.5-1-9.4-1.6c0.1-0.4,0.7-0.5,1.6-0.7c0.3,0.4,1.3,1.6,2.7,1.6h0.3c0,0-0.9-1.3-1.3-1.9c0,0,0.1-0.1,0.7-0.2
		c-0.1-0.2-0.1-0.2-0.1-0.4c-2.7,0.4-4.6,0.9-4.7,1.5c0,0.1,0,0.2,0,0.3c0,1,4.7,2,10,2C539.3,109.2,543.3,107.7,543.3,106.9z"/>
</g>
<g>
	<rect x="133" y="124.8" width="13" height="13"/>
	<path class="st7" d="M137.9,125.8c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2v-1.3h0.5c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0-0.2L137.9,125.8z"/>
	<path class="st7" d="M141,128.7c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1h-0.5
		v-1.3h-1.2v1.3h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L141,128.7z"/>
	<path class="st7" d="M143.7,129.3h-8.5c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C145,129.9,144.4,129.3,143.7,129.3 M143.7,129.8c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8h-8.5c-0.4,0-0.8-0.4-0.8-0.8
		v-4.9c0-0.4,0.4-0.8,0.8-0.8H143.7z"/>
	<path class="st7" d="M138.5,131.4c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C136.7,132.6,138.5,132.6,138.5,131.4"/>
	<path class="st7" d="M138.3,132.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C139,133.9,139.1,132.9,138.3,132.6"/>
	<path class="st7" d="M142.2,131.4c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C140.4,132.6,142.2,132.6,142.2,131.4"/>
	<path class="st7" d="M142,132.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C142.6,133.9,142.8,132.9,142,132.6"/>
	<rect x="133" y="146.3" width="13" height="13"/>
	<path class="st7" d="M137.9,147.3c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2V149h0.5c0.1,0,0.2,0,0.2-0.1s0-0.1,0-0.2L137.9,147.3z"/>
	<path class="st7" d="M141,150.2c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1
		l-0.5,0v-1.3h-1.2v1.3h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L141,150.2z"/>
	<path class="st7" d="M143.7,150.8h-8.5c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C145,151.4,144.4,150.8,143.7,150.8 M143.7,151.3c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8h-8.5c-0.4,0-0.8-0.4-0.8-0.8
		v-4.9c0-0.4,0.4-0.8,0.8-0.8H143.7z"/>
	<path class="st7" d="M138.5,152.9c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C136.7,154.1,138.5,154.1,138.5,152.9"/>
	<path class="st7" d="M138.3,154.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C139,155.4,139.1,154.4,138.3,154.1"/>
	<path class="st7" d="M142.2,152.9c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C140.4,154.1,142.2,154.1,142.2,152.9"/>
	<path class="st7" d="M142,154.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C142.6,155.4,142.8,154.4,142,154.1"/>
</g>
<g>
	<text transform="matrix(1 0 0 1 106.265 25.8604)" class="st2 st17">Planar Systems</text>
</g>
<text transform="matrix(1 0 0 1 90.9175 144.4561)" class="st2 st17">BUILDING-A</text>
<text transform="matrix(1 0 0 1 313.5801 143.6612)" class="st2 st17">BUILDING-B</text>
<text transform="matrix(1 0 0 1 518.1909 142.0245)" class="st2 st17">BUILDING-C</text>
<g>
	<rect x="169.4" y="132.8" width="17.7" height="18.4"/>
	<path class="st7" d="M180.9,145.5c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3-0.1-0.3-0.4c0.2-1,0.4-2.1,0.6-3.1c0.2-0.9,0.3-1.8,0.5-2.7h-0.3
		c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.7-0.2,1.3-0.4,2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2-0.1-0.4-0.2-0.4-0.5
		c0-0.1,0-0.3,0-0.5c0.2-1.3,0.5-2.5,0.7-3.8c0.1-0.4,0.3-0.6,0.7-0.7c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.8,0,2.8,0c0.2,0,0.3,0,0.5,0.1
		c0.4,0.1,0.6,0.3,0.6,0.6c0.2,0.9,0.4,1.8,0.5,2.7c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.4-0.8,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-1-0.4-2-0.6-3c0,0,0-0.1,0-0.2h-0.3c0.1,0.5,0.2,1,0.3,1.5c0.3,1.4,0.5,2.8,0.8,4.2
		c0.1,0.3,0,0.4-0.3,0.4c-0.3,0-0.6,0-0.9,0v0.2c0,1.1,0,2.2,0,3.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.5,0.3
		c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.3v-0.2H182v0.2c0,1.1,0,2.2,0,3.3c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.5,0.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.4L180.9,145.5L180.9,145.5z"/>
	<path class="st7" d="M174,143.8h-0.2v0.2c0,1.7,0,3.3,0,5c0,0.4-0.2,0.7-0.5,0.7c-0.4,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3.2,0-6.4,0-9.6v-0.2h-0.2v0.2c0,1.3,0,2.5,0,3.8c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-1.4,0-2.7,0-4.1c0-0.8,0.7-1.4,1.4-1.5c1,0,2.1,0,3.1,0c0.8,0,1.4,0.7,1.4,1.4c0,1.4,0,2.8,0,4.1c0,0,0,0.1,0,0.1
		c0,0.4-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2v-3.7v-0.2h-0.2v0.2c0,3.2,0,6.4,0,9.7c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2V144L174,143.8L174,143.8z"/>
	<path class="st7" d="M182.2,137.1c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
		C183.6,136.5,183,137.1,182.2,137.1"/>
	<path class="st7" d="M172.4,135.7c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
		C173.1,137.1,172.4,136.5,172.4,135.7"/>
</g>
<g>
	<rect x="354.6" y="124.8" width="13" height="13"/>
	<path class="st7" d="M359.6,125.8c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2v-1.3h0.5c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0-0.2L359.6,125.8z"/>
	<path class="st7" d="M362.7,128.7c0,0.1,0.1,0.1,0.2,0.1s0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1h-0.5v-1.3
		h-1.2v1.3h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L362.7,128.7z"/>
	<path class="st7" d="M365.4,129.3h-8.5c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C366.7,129.9,366.1,129.3,365.4,129.3 M365.4,129.8c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8h-8.5c-0.4,0-0.8-0.4-0.8-0.8
		v-4.9c0-0.4,0.4-0.8,0.8-0.8H365.4z"/>
	<path class="st7" d="M360.2,131.4c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C358.4,132.6,360.2,132.6,360.2,131.4"/>
	<path class="st7" d="M360,132.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C360.6,133.9,360.8,132.9,360,132.6"/>
	<path class="st7" d="M363.8,131.4c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C362.1,132.6,363.8,132.6,363.8,131.4"/>
	<path class="st7" d="M363.7,132.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C364.3,133.9,364.4,132.9,363.7,132.6"/>
	<rect x="354.6" y="146.3" width="13" height="13"/>
	<path class="st7" d="M359.6,147.3c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2V149h0.5c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0-0.2L359.6,147.3z"/>
	<path class="st7" d="M362.7,150.2c0,0.1,0.1,0.1,0.2,0.1s0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0
		v-1.3h-1.2v1.3h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L362.7,150.2z"/>
	<path class="st7" d="M365.4,150.8h-8.5c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C366.7,151.4,366.1,150.8,365.4,150.8 M365.4,151.3c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8h-8.5c-0.4,0-0.8-0.4-0.8-0.8
		v-4.9c0-0.4,0.4-0.8,0.8-0.8H365.4z"/>
	<path class="st7" d="M360.2,152.9c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C358.4,154.1,360.2,154.1,360.2,152.9"/>
	<path class="st7" d="M360,154.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C360.6,155.4,360.8,154.4,360,154.1"/>
	<path class="st7" d="M363.8,152.9c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C362.1,154.1,363.8,154.1,363.8,152.9"/>
	<path class="st7" d="M363.7,154.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C364.3,155.4,364.4,154.4,363.7,154.1"/>
</g>
<g>
	<rect x="547.8" y="85.7" width="13" height="13"/>
	<path class="st7" d="M552.7,86.7c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2v-1.3h0.5c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0-0.2L552.7,86.7z"/>
	<path class="st7" d="M555.8,89.6c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1h-0.5
		v-1.3h-1.2V88h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L555.8,89.6z"/>
	<path class="st7" d="M558.5,90.3H550c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C559.8,90.9,559.2,90.3,558.5,90.3 M558.5,90.8c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8H550c-0.4,0-0.8-0.4-0.8-0.8v-4.9
		c0-0.4,0.4-0.8,0.8-0.8H558.5z"/>
	<path class="st7" d="M553.3,92.3c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C551.5,93.5,553.3,93.5,553.3,92.3"/>
	<path class="st7" d="M553.1,93.5c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C553.7,94.9,553.9,93.9,553.1,93.5"/>
	<path class="st7" d="M557,92.3c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C555.2,93.5,557,93.5,557,92.3"/>
	<path class="st7" d="M556.8,93.5c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C557.4,94.9,557.6,93.9,556.8,93.5"/>
	<rect x="547.8" y="107.3" width="13" height="13"/>
	<path class="st7" d="M552.7,108.3c0-0.1-0.1-0.1-0.2-0.1s-0.2,0-0.2,0.1l-1.1,1.4c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5v1.3
		h1.2v-1.3h0.5c0.1,0,0.2,0,0.2-0.1c0-0.1,0-0.1,0-0.2L552.7,108.3z"/>
	<path class="st7" d="M555.8,111.2c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1l1.1-1.4c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1
		l-0.5,0v-1.3h-1.2v1.3h-0.5c-0.1,0-0.2,0-0.2,0.1c0,0.1,0,0.1,0,0.2L555.8,111.2z"/>
	<path class="st7" d="M558.5,111.8H550c-0.7,0-1.3,0.6-1.3,1.3v4.9c0,0.7,0.6,1.3,1.3,1.3h8.5c0.7,0,1.3-0.6,1.3-1.3v-4.9
		C559.8,112.4,559.2,111.8,558.5,111.8 M558.5,112.3c0.4,0,0.8,0.4,0.8,0.8v4.9c0,0.4-0.4,0.8-0.8,0.8H550c-0.4,0-0.8-0.4-0.8-0.8
		v-4.9c0-0.4,0.4-0.8,0.8-0.8H558.5z"/>
	<path class="st7" d="M553.3,113.8c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C551.5,115,553.3,115,553.3,113.8"/>
	<path class="st7" d="M553.1,115.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C553.7,116.4,553.9,115.4,553.1,115.1"/>
	<path class="st7" d="M557,113.8c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-0.9,0.9C555.2,115,557,115,557,113.8"/>
	<path class="st7" d="M556.8,115.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.8,0.3-0.6,1.3-0.6,2.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4l0.2,1.1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.2-1.1
		c0,0.2,0.1,0.3,0.3,0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.4C557.4,116.4,557.6,115.4,556.8,115.1"/>
</g>
<g>
	<rect x="408.1" y="132.8" width="17.7" height="18.4"/>
	<path class="st7" d="M419.6,145.5c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3-0.1-0.3-0.4c0.2-1,0.4-2.1,0.6-3.1c0.2-0.9,0.3-1.8,0.5-2.7h-0.3
		c-0.1,0.4-0.1,0.8-0.2,1.2c-0.1,0.7-0.2,1.3-0.4,2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2-0.1-0.4-0.2-0.4-0.5
		c0-0.1,0-0.3,0-0.5c0.2-1.3,0.5-2.5,0.7-3.8c0.1-0.4,0.3-0.6,0.7-0.7c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.8,0,2.8,0c0.2,0,0.3,0,0.5,0.1
		c0.4,0.1,0.6,0.3,0.6,0.6c0.2,0.9,0.4,1.8,0.5,2.7c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.4-0.8,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-1-0.4-2-0.6-3c0,0,0-0.1,0-0.2h-0.3c0.1,0.5,0.2,1,0.3,1.5c0.3,1.4,0.5,2.8,0.8,4.2
		c0.1,0.3,0,0.4-0.3,0.4c-0.3,0-0.6,0-0.9,0v0.2c0,1.1,0,2.2,0,3.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.5,0.3
		c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.3v-0.2h-0.4v0.2c0,1.1,0,2.2,0,3.3c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.5,0.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.4L419.6,145.5L419.6,145.5z"/>
	<path class="st7" d="M412.6,143.8h-0.2v0.2c0,1.7,0,3.3,0,5c0,0.4-0.2,0.7-0.5,0.7c-0.4,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3.2,0-6.4,0-9.6v-0.2h-0.2v0.2c0,1.3,0,2.5,0,3.8c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-1.4,0-2.7,0-4.1c0-0.8,0.7-1.4,1.4-1.5c1,0,2.1,0,3.1,0c0.8,0,1.4,0.7,1.4,1.4c0,1.4,0,2.8,0,4.1c0,0,0,0.1,0,0.1
		c0,0.4-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2v-3.7v-0.2H414v0.2c0,3.2,0,6.4,0,9.7c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2V144L412.6,143.8L412.6,143.8z"/>
	<path class="st7" d="M420.9,137.1c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
		C422.3,136.5,421.7,137.1,420.9,137.1"/>
	<path class="st7" d="M411.1,135.7c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4
		C411.7,137.1,411.1,136.5,411.1,135.7"/>
</g>
<g>
	<rect x="602" y="94.1" width="17.7" height="18.4"/>
	<path class="st7" d="M613.5,106.8c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3-0.1-0.3-0.4c0.2-1,0.4-2.1,0.6-3.1c0.2-0.9,0.3-1.8,0.5-2.7h-0.3
		c-0.1,0.4-0.2,0.8-0.2,1.2c-0.1,0.7-0.2,1.3-0.4,2c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.3,0.3-0.5,0.3c-0.2-0.1-0.4-0.2-0.4-0.5
		c0-0.1,0-0.3,0-0.5c0.2-1.3,0.5-2.5,0.7-3.8c0.1-0.4,0.3-0.6,0.7-0.7c0.2,0,0.5-0.1,0.7-0.1c0.9,0,1.8,0,2.8,0c0.2,0,0.3,0,0.5,0.1
		c0.4,0.1,0.6,0.3,0.6,0.6c0.2,0.9,0.4,1.8,0.5,2.7c0.1,0.4,0.2,0.8,0.2,1.2c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.6,0.4-0.8,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-1-0.4-2-0.6-3c0,0,0-0.1,0-0.2h-0.3c0.1,0.5,0.2,1,0.3,1.5c0.3,1.4,0.5,2.8,0.8,4.2
		c0.1,0.3,0,0.4-0.3,0.4c-0.3,0-0.6,0-0.9,0v0.2c0,1.1,0,2.2,0,3.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.5,0.3
		c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.3v-0.2h-0.4v0.2c0,1.1,0,2.2,0,3.3c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.5,0.3c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1,0-2.2,0-3.4L613.5,106.8L613.5,106.8z"/>
	<path class="st7" d="M606.6,105.1h-0.2v0.2c0,1.7,0,3.3,0,5c0,0.4-0.2,0.7-0.5,0.7c-0.4,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3.2,0-6.4,0-9.6v-0.2h-0.2v0.2c0,1.3,0,2.5,0,3.8c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.6,0.2-0.8,0c-0.1-0.2-0.2-0.3-0.2-0.5
		c0-1.4,0-2.7,0-4.1c0-0.8,0.7-1.4,1.4-1.5c1,0,2.1,0,3.1,0c0.8,0,1.4,0.7,1.4,1.4c0,1.4,0,2.8,0,4.1c0,0,0,0.1,0,0.1
		c0,0.4-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2v-3.7v-0.2h-0.2v0.2c0,3.2,0,6.4,0,9.7c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.7c0-0.1,0-0.1,0-0.2v-4.9L606.6,105.1L606.6,105.1z"/>
	<path class="st7" d="M614.8,98.4c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4
		C616.2,97.8,615.6,98.4,614.8,98.4"/>
	<path class="st7" d="M605,97c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C605.7,98.4,605,97.8,605,97"/>
</g>
<g>
	<text transform="matrix(1 0 0 1 137.3019 69.4113)" class="st2 st12">A-124</text>
</g>
<g>
	<text transform="matrix(1 0 0 1 117.4209 33.2155)" class="st2 st3">A-124A</text>
</g>
<g class="st18">
	<polygon class="st19" points="470.1,92.6 471,94.1 472.7,94.1 471.6,95.5 472,97.1 470.4,96.4 469,97.3 469.2,95.7 467.9,94.6 
		469.5,94.2 	"/>
	<text transform="matrix(1 0 0 1 428.3293 36.9386)" class="st19 st2 st3">Quadrille</text>
	<text transform="matrix(1 0 0 1 440.1052 42.537)" class="st19 st2 st3">C-110</text>
	<path class="st20" d="M469.8,94.9c0,0-27.8-17.8-27.4-50.9"/>
</g>
<line class="st21" x1="620.7" y1="122.3" x2="564.1" y2="122.3"/>
<rect class="st22" width="644.8" height="255"/>
<g id="Layer_2">
</g>
<path class="st23" d="M206.9,101.1c0,0-24.3-16-12.5-46"/>
<g>
	<polygon points="142.2,73.2 142.7,74.7 144.3,74.7 143,75.6 143.5,77.1 142.2,76.1 141,77.1 141.5,75.6 140.2,74.7 141.8,74.7 	"/>
	<path class="st23" d="M141.6,75.2c0,0-23.7-10.1-15.6-40"/>
</g>
<!--### Start Room Masks ###-->

    <polygon v-on:click="clickStore($event)" id="MA117" class="st00" points="113.1,75.2 113.1,60.8 95.3,60.8 95.3,82.3 105.7,82.3 105.7,89.4 116.3,89.4 116.3,78.8 
					"/>
	<polygon v-on:click="clickStore($event)" id="MA124" class="st00" points="164.6,61.7 164.2,59.2 163,56 160.5,53.1 157,49.7 152.1,45.7 147.3,43.9 140.9,43.3 
		133.1,44 129.9,44.7 129.9,89.3 150.3,89.2 150.3,89.4 150.6,89.1 150.6,82.3 164.8,69.4 164.9,61.7 	"/>
	<polygon v-on:click="clickStore($event)" id="MA160" class="st00" points="129.4,199.7 123.2,199.7 123.2,164 102.5,164 102.5,237.9 109,236.2 118.2,234.1 
		126.1,232.2 148.8,226.8 164.2,223 164.2,221.2 164.1,214 129.4,214 	"/>
	<polygon v-on:click="clickStore($event)" id="MB100" class="st00" points="200.9,106.9 201,86.5 216.9,86.5 216.9,94.6 216.8,123.9 213.2,123.6 213.2,129.5 
		206.3,129.3 206.1,136.2 200.8,136.3 200.9,114 	"/>
	<polygon v-on:click="clickStore($event)" id="MB101" class="st00" points="249.8,86.9 249.8,86.9 233.7,86.9 233.7,94.9 233.9,123.3 237.7,123.3 237.7,130 
			244.4,130.1 244.2,136.4 249.2,136.5 249.2,139.5 253.3,139.5 253.3,86.9 		"/>
	<polygon v-on:click="clickStore($event)" id="MB108" class="st00" points="305.1,89.1 305.1,100 321.3,100 321.3,44.9 320.3,44.4 317.4,43.4 310.8,43.3 305.7,44.2 
		300.5,45.6 295.6,48.5 292.3,51.3 290.1,54.8 287.8,58.5 286.8,60.3 286.8,60.3 286.8,89.1 	"/>
	<polygon v-on:click="clickStore($event)" id="MB112" class="st00" points="356.7,52.6 352.2,51.5 324.4,45.3 321.3,44.9 321.3,100 356.7,100 	"/>
	<polygon v-on:click="clickStore($event)" id="MB158" class="st00" points="407.9,183.6 427.1,183.6 427.1,159.4 407.5,159.7 407.7,151.5 400.9,151.3 400.9,145.3 
			379.5,145.5 372.1,152.8 372,170.7 407.9,170.7 		"/>
	<polygon v-on:click="clickStore($event)" id="MB175" class="st00" points="304.8,186.3 304.7,196.7 295.3,196.6 295.3,225.7 303.7,228.2 312,230.4 318.9,232.2 
		326.1,233.8 327.2,234 327.2,186.2 	"/>
	<polygon v-on:click="clickStore($event)" id="MC100" class="st00" points="462,80 462,79.8 457.9,76.3 457.9,76.8 427.6,76.8 427.5,139.7 462,139.8 462,80.7 	"/>
	<polygon v-on:click="clickStore($event)" id="MC110" class="st00" points="480.2,109.5 480.2,109.5 480.2,80.4 478.9,80.4 462,80.4 462,139.5 480.2,139.6 	"/>
	<polygon v-on:click="clickStore($event)" id="MC114" class="st00" points="480.2,139.4 504.9,139.5 514.5,131.3 514.5,109.4 480.2,109.4 	"/>
	<polygon v-on:click="clickStore($event)" id="MC116" class="st00" points="510.2,82.5 510.2,79.5 496.8,79.5 496.9,80.7 480.6,80.7 480.6,109.8 514.9,109.8 
		514.9,82.5 	"/>
    <polygon v-on:click="clickStore($event)" id="MC120" class="st00" points="461.9,80.7 478.9,80.7 496.4,80.7 496.3,79.4 509.7,79.4 509.7,82.4 514.5,82.4 514.5,64.2 
		515.7,64.2 495.9,64.2 495.9,36 492.6,32.5 458.1,41.6 457.9,76.3 461.9,79.8 	"/>
	<polygon v-on:click="clickStore($event)" id="MC126" class="st00" points="526.8,34.7 526.9,31.8 523.3,31.5 523.1,29.5 492.8,29.5 492.7,32.3 492.7,32.3 496,35.9 
		496,64 515.8,64 521.5,63.9 528.9,57.9 529,34.5 	"/>
	<polygon v-on:click="clickStore($event)" id="MC130" class="st00" points="594.2,68.8 620.1,68.8 620,61.3 615.2,61.2 605.6,28.9 555.1,29 555,32.1 552,31.7 
			551.5,34.2 550.2,34.4 550,57.1 556.8,63.9 564.5,63.9 564.5,83.7 578.5,83.7 		"/>
	<polygon v-on:click="clickStore($event)" id="MC138" class="st00" points="573.9,100.3 621,100 620.9,83.5 617.3,83.5 617.3,80 620.2,79.8 620.1,68.8 594.2,68.8 
		578.5,83.7 564.5,83.7 564.5,91.9 	"/>
	<polygon v-on:click="clickStore($event)" id="MC150" class="st00" points="620.2,115.7 617.3,115.7 617.4,114.4 620.9,114.4 621,106.7 573.1,106.5 563.9,115.7 
		564.1,122.3 620.2,122.3 	"/>

    <polygon v-on:click="clickStore($event)" id="MC162" class="st00" points="519.7,146.9 519.7,160 519.7,160 512.7,167 512.7,171.9 512.7,190.8 515.9,190 518.9,189.4 
		529.3,186.9 534.4,185.6 539.5,184.3 539.5,147 	"/>
	<polygon v-on:click="clickStore($event)" id="MC166" class="st00" points="512.7,171.9 512.7,167 519.7,160 519.7,146.9 495.7,146.8 495.7,171.9 	"/>
	<polygon v-on:click="clickStore($event)" id="MC170" class="st00" points="477.8,167.6 477.7,167.6 477.7,171.9 495.7,171.9 495.7,146.8 477.8,146.7 	"/>
	

	<polygon v-on:click="clickStore($event)" id="MA123" class="st00" points="81.5,94.8 81.4,120.7 144.4,120.6 144,94.8"/>


	<polygon v-on:click="clickStore($event)" id="MC154" class="st00" points="593.9,149.2 569.4,127.1 564.3,127.1 564.4,132.9 564.8,146.9 558.4,146.9 558.4,152.2 
		565.1,159 565.1,159.1 565.2,159.3 565.2,177.6 565.2,177.7 565.2,177 565.2,177.7 567.7,177.7 567.7,175.6 571.7,175.8 
		571.7,177.6 585.7,177.6 585.7,175.9 585.6,174.4 593.9,172.3 604.7,169.6 613.2,167.7 620.1,166 620.1,156.4 620.1,149.2 	"/>

</svg>

    <store-modal
      :title="store.name"
      :image="store.business_image"
      :suite="store.suite"
      :website="store.website"
      :contact="store.contact"
      :phone="store.phone"
      :email="store.email"
      :target="target"
      :targetzone="targetzone"
     
    />
  </div>
  
</template>

<script>
// @ is an alias to /src
import StoreModal from '@/components/StoreModal'
export default {
  name: 'floor1',
  components:{
    StoreModal,
  },
  data(){
    return {
      store: 'B-100',
      target: null,
      MC126: 'st00',
      MA117: 'st00',
      MA124: 'st00',
      MA123: 'st00',
      MB100: 'st00',
      MA160: 'st00',
      MB101: 'st00',
      MB108: 'st00',
      MB112: 'st00',
      MB175: 'st00',
      MB158: 'st00',
      MC100: 'st00',
      MA120: 'st00',
      MA126: 'st00',
      MC130: 'st00',
      MC120: 'st00',
      MC138: 'st00',
      MC150: 'st00',
      MC162: 'st00',
      MC166: 'st00',
      MC170: 'st00',
      MC110: 'st00',
      MC114: 'st00',
      MC116: 'st00',
	  MC154: 'st00',
	  selected: null,
      targetzone:'B-100',
      id: (this.$route.params.id ? this.$route.params.id : null),
    }
  },
  methods:{
    unselStore(e) {
      this[e] = 'st00';
    },
    
    clickStore(e) {
      	setTimeout(() => {
                  this.$root.$emit('bv::hide::popover');
        }, 0);
      
            this.targetzone = '';
      		
			 let num = document.getElementsByClassName("selected").length;
            if(num){
            	

				      const allChildElementsOfParentWithClass = document.querySelectorAll('.selected');

				

              allChildElementsOfParentWithClass.forEach((element) => {
                element.classList.remove('selected');
              });	
            }
			
      		this.target = e.target.id.slice(1);
     		this.store = this.storeData(this.target.slice(0,1) + '-' + this.target.slice(1));
      		this.targetzone = 'M' + this.target
      		this[this.target] = 'st01';

			//console.log(this.targetzone.substring(0, 2));
			let zoneString =  this.targetzone.substring(0, 2);
			if (zoneString == "MA") {
			  	var className = "st00";
        		var bodyClass = "section-right";
			} else if(zoneString == "MB"){
			  	var className = "st00";
        		var bodyClass = "section-mid";
			}else if(zoneString == "MC"){
			  	var className = "st00";
        		var bodyClass = "section-left";
			}
			e.target.className.baseVal = className+" selected";
			document.body.className = '';
      		document.body.classList.add(bodyClass);
     
            setTimeout(() => {
                  this.$root.$emit('bv::show::popover',this.targetzone)
            }, 400);
         
            
    },
    resetModal() {
      console.log("reset modal");
    },
    storeData(payload) {
      return this.stores.find(x => x.suite === payload)
    },
     
    showStorePopover(){
          	this.targetzone = 'M' + this.$route.params.id.replace(/-/g,'');
		  	let zoneString =  this.targetzone.substring(0, 2);
			if (zoneString == "MA") {
			  	var className = "st00";
        		var bodyClass = "section-right";
			} else if(zoneString == "MB"){
			  	var className = "st00";
        		var bodyClass = "section-mid";
			}else if(zoneString == "MC"){
			  	var className = "st00";
        		var bodyClass = "section-left";
			}
			document.body.className = '';
      		document.body.classList.add(bodyClass);
		
				
          setTimeout(() => {
                   this.store = this.storeData(this.$route.params.id);
				   if(this.$route.params.id){
					
					//router-link-exact-active
				   }
                  this.$root.$emit('bv::show::popover',this.targetzone);
				  var d = document.getElementById(this.targetzone);
				  d.classList.add('selected');
				
            }, 1000);
    }
  },
  mounted() {
    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  },
  computed:{
    stores() {
      return this.$store.state.suites;
    }
  },
  created: function(){
		 document.body.className = '';
         if (typeof(this.$route.params.id) !== 'undefined'){
          setTimeout(() => {
			        this.showStorePopover()
          }, 400);    
	      }
  }
}
</script>

<style type="text/css">
.selected
   {
    stroke: #002868 !important;
    stroke-width: 1px;
    stroke-linejoin: round;
    fill: rgb(255 255 255 / 33%) !important;
    cursor: pointer;
    fill-opacity: .7 !important;
    }

	/*.st0{fill:#DCE4A2;}
	.st00{fill-opacity: .0;}
	.st1{fill:#90C5E5;}
	.st2{fill:none;}
	.st3{fill:#C4E3F7;}
	.st4{fill:#FFFFFF;}
	.st5{font-family:'Montserrat', sans-serif;}
	.st6{font-size:5.8846px;}
	.st7{fill:#E7EAE8;}
	.st8{fill:none;stroke:#231F20;stroke-width:0.75;stroke-miterlimit:10;}
	.st9{font-size:11px;}
	.st10{font-size:4.6px;}
	.st11{font-size:6px;}
	.st12{font-size:4px;}
	.st13{font-size:5.3354px;}
	.st14{font-size:4.8254px;}
	.st15{font-size:5px;}
	.st16{font-size:4.5px;}
	.st17{font-size:5.1px;}
	.st18{font-size:5.8863px;}
	.st19{font-size:5.4923px;}
	.st20{fill:#333333;}
	.st21{fill:none;stroke:#000000;stroke-width:0.444;stroke-linecap:round;stroke-miterlimit:17.777;}
	.st22{display:none;}
	.st23{display:inline;}
	.st24{display:inline;fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st25{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st26{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st27{opacity:7.000000e-02;fill:none;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
	.st28{fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;}*/

	.st0{fill:#DCE4A2;}
	.st1{fill:#90C5E5;}
	.st2{font-family:'Montserrat', sans-serif;}
	.st3{font-size:5.8846px;}
	.st4{fill:none;}
	.st5{fill:#C4E3F7;}
	.st6{font-size:5.3354px;}
	.st7{fill:#FFFFFF;}
	.st8{fill:#E7EAE8;}
	.st9{fill:none;stroke:#231F20;stroke-width:0.75;stroke-miterlimit:10;}
	.st10{font-size:8.6308px;}
	.st11{font-size:4.8254px;}
	.st12{font-size:5px;}
	.st13{font-size:4.5px;}
	.st14{font-size:5.1px;}
	.st15{font-size:5.4923px;}
	.st16{fill:#333333;}
	.st17{font-size:5.8863px;}
	.st18{display:none;}
	.st19{display:inline;}
	.st20{display:inline;fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st21{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st22{opacity:7.000000e-02;fill:none;stroke:#FFFFFF;stroke-width:0.25;stroke-miterlimit:10;}
	.st23{fill:none;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;}
	.st00{fill-opacity: .0;}


	  .section-mid .popover{
        transform:translate3d(calc(100% - 140px), 150px, 0px) !important;
      }
      .section-left .popover,.section-right .popover{
         top: 50% !important;
        right: 50% !important;
        transform: translate(50%,-50%) !important;
        left: auto !important;
      }
</style>