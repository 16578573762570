import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    suites: [{
      id: null,
      ordering: null,
      state: null,
      checked_out: null,
      checked_out_time: null,
      created_by: null,
      modified_by: null,
      name: null,
      suite: null,
      business_image: null,
      phone: null,
      website: null,
      product_lines: null,
      map_location: null
    }]
  },
  mutations: {
    GET_SUITES(state, payload) {
      state.suites = payload;
    },
  },
  actions: {
    loadSuites(context) {
      console.log("load suites");
      return new Promise((resolve, reject) => {
        const auth = {
          headers: {
            'Authorization': 'Bearer 671f807c6d34a441a030006fc11ed478',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        };
        Vue.$http.get('/api/suites/suites','',auth)
            .then(response => {
              context.commit('GET_SUITES', response.data.data);
              if (process.env.NODE_ENV === 'development') {
                console.log(response.data.data);
              }
            })
            .catch(error => console.log({error}));
        setTimeout(() => {
          resolve()
        }, 0)
      })
    },
  },
  modules: {
  }
});
