<template>
	<div class="floor3">
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 636 285.3" style="enable-background:new 0 0 636 285.3;" xml:space="preserve">

<g>
	<rect x="135.7" y="136.4" width="12.1" height="12.1"/>
	<path class="st0" d="M140.3,137.4c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L140.3,137.4z"/>
	<path class="st0" d="M143.2,140.1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0
		v-1.2h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L143.2,140.1z"/>
	<path class="st0" d="M145.7,140.7h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
		C146.9,141.2,146.4,140.7,145.7,140.7 M145.7,141.1c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
		v-4.5c0-0.4,0.3-0.8,0.8-0.8H145.7z"/>
	<path class="st0" d="M140.9,142.6c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C139.2,143.7,140.9,143.7,140.9,142.6"/>
	<path class="st0" d="M140.7,143.7c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C141.3,144.9,141.4,144,140.7,143.7"/>
	<path class="st0" d="M144.3,142.6c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C142.7,143.7,144.3,143.7,144.3,142.6"/>
	<path class="st0" d="M144.1,143.7c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C144.7,144.9,144.9,144,144.1,143.7"/>
</g>
<g>
	<rect x="135.7" y="158.3" width="12.1" height="12.1"/>
	<path class="st0" d="M140.3,159.3c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L140.3,159.3z"/>
	<path class="st0" d="M143.2,162c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0
		v-1.2h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L143.2,162z"/>
	<path class="st0" d="M145.7,162.6h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
		C146.9,163.1,146.4,162.6,145.7,162.6 M145.7,163c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
		v-4.5c0-0.4,0.3-0.8,0.8-0.8H145.7z"/>
	<path class="st0" d="M140.9,164.5c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C139.2,165.6,140.9,165.6,140.9,164.5"/>
	<path class="st0" d="M140.7,165.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C141.3,166.9,141.4,165.9,140.7,165.6"/>
	<path class="st0" d="M144.3,164.5c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C142.7,165.6,144.3,165.6,144.3,164.5"/>
	<path class="st0" d="M144.1,165.6c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C144.7,166.9,144.9,165.9,144.1,165.6"/>
</g>
<g>
	<rect x="350.8" y="135.9" width="12.1" height="12.1"/>
	<path class="st0" d="M355.4,136.8c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L355.4,136.8z"/>
	<path class="st0" d="M358.3,139.5c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0v-1.2
		h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L358.3,139.5z"/>
	<path class="st0" d="M360.8,140.1h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
		C362,140.7,361.5,140.1,360.8,140.1 M360.8,140.6c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
		v-4.5c0-0.4,0.3-0.8,0.8-0.8H360.8z"/>
	<path class="st0" d="M355.9,142c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C354.3,143.1,355.9,143.1,355.9,142"/>
	<path class="st0" d="M355.8,143.2c-0.1-0.1-0.3-0.1-0.5-0.1H355c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C356.4,144.4,356.5,143.5,355.8,143.2"/>
	<path class="st0" d="M359.4,142c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C357.7,143.1,359.4,143.1,359.4,142"/>
	<path class="st0" d="M359.2,143.2c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C359.8,144.4,359.9,143.5,359.2,143.2"/>
</g>
<g>
	<rect x="350.8" y="157.8" width="12.1" height="12.1"/>
	<path class="st0" d="M355.4,158.7c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L355.4,158.7z"/>
	<path class="st0" d="M358.3,161.4c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0v-1.2
		h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L358.3,161.4z"/>
	<path class="st0" d="M360.8,162h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
		C362,162.6,361.5,162,360.8,162 M360.8,162.5c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8v-4.5
		c0-0.4,0.3-0.8,0.8-0.8H360.8z"/>
	<path class="st0" d="M355.9,163.9c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C354.3,165.1,355.9,165.1,355.9,163.9"/>
	<path class="st0" d="M355.8,165.1c-0.1-0.1-0.3-0.1-0.5-0.1H355c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C356.4,166.3,356.5,165.4,355.8,165.1"/>
	<path class="st0" d="M359.4,163.9c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C357.7,165.1,359.4,165.1,359.4,163.9"/>
	<path class="st0" d="M359.2,165.1c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C359.8,166.3,359.9,165.4,359.2,165.1"/>
</g>
<g>
	<rect x="529.4" y="94.7" width="12.1" height="12.1"/>
	<path class="st0" d="M534,95.6c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L534,95.6z"/>
	<path class="st0" d="M536.9,98.3c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0v-1.2
		h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L536.9,98.3z"/>
	<path class="st0" d="M539.4,98.9h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2v-4.5
		C540.6,99.4,540.1,98.9,539.4,98.9 M539.4,99.4c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8v-4.5
		c0-0.4,0.3-0.8,0.8-0.8H539.4z"/>
	<path class="st0" d="M534.6,100.8c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C532.9,101.9,534.6,101.9,534.6,100.8"/>
	<path class="st0" d="M534.4,101.9c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C535,103.2,535.1,102.2,534.4,101.9"/>
	<path class="st0" d="M538,100.8c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C536.3,101.9,538,101.9,538,100.8"/>
	<path class="st0" d="M537.8,101.9c-0.1-0.1-0.3-0.1-0.5-0.1H537c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C538.4,103.2,538.5,102.2,537.8,101.9"/>
</g>
<g>
	<rect x="529.4" y="116.6" width="12.1" height="12.1"/>
	<path class="st0" d="M534,117.5c0-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0.1l-1,1.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.2,0.1h0.5
		v1.2h1.1v-1.2h0.5c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2L534,117.5z"/>
	<path class="st0" d="M536.9,120.2c0,0.1,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1l1-1.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.5,0v-1.2
		h-1.1v1.2h-0.5c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0,0.2L536.9,120.2z"/>
	<path class="st0" d="M539.4,120.8h-7.9c-0.7,0-1.2,0.5-1.2,1.2v4.5c0,0.7,0.5,1.2,1.2,1.2h7.9c0.7,0,1.2-0.5,1.2-1.2V122
		C540.6,121.3,540.1,120.8,539.4,120.8 M539.4,121.3c0.4,0,0.8,0.3,0.8,0.8v4.5c0,0.4-0.3,0.8-0.8,0.8h-7.9c-0.4,0-0.8-0.3-0.8-0.8
		V122c0-0.4,0.3-0.8,0.8-0.8H539.4z"/>
	<path class="st0" d="M534.6,122.7c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C532.9,123.8,534.6,123.8,534.6,122.7"/>
	<path class="st0" d="M534.4,123.8c-0.1-0.1-0.3-0.1-0.5-0.1h-0.3c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C535,125.1,535.1,124.2,534.4,123.8"/>
	<path class="st0" d="M538,122.7c0-0.6-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.3-0.8,0.8C536.3,123.8,538,123.8,538,122.7"/>
	<path class="st0" d="M537.8,123.8c-0.1-0.1-0.3-0.1-0.5-0.1H537c-0.2,0-0.4,0-0.5,0.1c-0.7,0.3-0.6,1.3-0.6,2
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3l0.1,1c0,0.1,0.1,0.3,0.2,0.3h0.7c0.1,0,0.2-0.1,0.2-0.3l0.1-1
		c0,0.2,0.1,0.3,0.3,0.3c0,0,0,0,0,0c0.2,0,0.3-0.2,0.3-0.3C538.4,125.1,538.5,124.2,537.8,123.8"/>
</g>
<line class="st1" x1="113.3" y1="202.7" x2="113.3" y2="237"/>
<g>
	<text transform="matrix(1 0 0 1 488.4316 233.2897)" class="st2 st3 st7">DCOTA 3rd Floor</text>
</g>
<polygon class="st4" points="41.9,156.6 87.8,156.5 96.4,166.1 96.3,202.7 152.6,202.6 152.8,162.5 159.1,156.5 205.8,156.8 
	206.1,169.4 208.2,169.7 208.1,236.7 190.6,236.7 190.4,232.1 160.6,232.1 160.4,236.7 107.8,236.7 107.6,232.6 78.3,232.7 
	78.2,236.5 41.8,236.6 "/>
<path class="st4" d="M41.9,70.2l32.4,0c62.2-16.4,78.6-20.6,70.8-19c-5.7,1.2,18.7-6.6,27.7,18.7l26.1,0.2L202,86l5,0.2l0.1,32.5
	l15.3,0.2l-0.2,30.9l-62.8,0.2l-5.9-5.9l-0.1-39.4l-56.7-0.5l-0.1,36.8l-9.4,9.1c0,0-25,0.9-31.4,0c-5.5-0.7-13.5-5.4-13.5-14.9
	C42.1,124.7,41.9,70.2,41.9,70.2"/>
<path class="st5" d="M367.6,111.7H304l-0.1,28.2l-9.5,10l-79.8-0.5v-19.8h-6.9l0.4-11.2l6,0.1l-0.3-22.2l32.4,0.2l-0.1,22h6.4
	l0.1-32.4l4.8-0.2l4.3-15.6l25-0.6c1.6-4.1,7.4-16.7,19.1-18.6c11.2-1.8,48.3,10.2,54.1,12c5.9,1.8,27.2,7.1,27.2,7.1l26.1-0.2h5
	l0.8,31.2h27.9l3.1,3.2l0.1,45.3l-32.2,0.1l-43,0.2l-7.2-7.9"/>
<polygon class="st5" points="205.8,156.7 294.2,156.6 304.3,166.5 304.2,195.2 367,195.1 367.1,163.4 374.3,156.3 400.3,156.5 
	418.5,156.5 418.6,169.5 418.5,236.1 382.7,236.5 382.8,231.3 353.8,231.6 353.6,236.2 300.3,236.1 300.2,231.5 271,231.3 
	270.9,236.4 253.1,236.4 253.3,220.4 208,220.5 208,169.7 205.8,169.7 "/>
<polygon class="st6" points="598.5,115.4 598.5,123.2 596.5,123.3 596.4,125.1 598.7,125.2 598.9,175.3 567.2,183.9 567.2,187.6 
	553.3,187.5 553.2,185.2 533.6,185.4 533.5,188.2 449.8,188.1 449.6,156.7 542.7,156.7 546.3,152.5 545.9,125.4 555.9,115.6 "/>
<polygon class="st6" points="418.6,149.8 450.2,149.8 490.6,149.7 499.9,139.9 499.9,72 546.3,71.8 546.2,99.5 555.2,108 
	598.8,107.8 598.8,91 596.8,91.1 596.6,87.9 598.8,87.7 598.6,68.3 594.2,68.2 585.6,35.9 537.3,36 537.3,39.1 533.9,38.7 
	533.5,25.2 511.9,25.2 511.3,38.7 507.9,38.7 508,36 479.2,35.9 479,39 446.9,48.4 446.8,101.1 418.6,100.7 "/>
<path d="M222.2,149.4l-63.2,0.1l-5.6-5.6v-39.7l-57.7,0l0.1,36.8l-8.6,8.6l0.3,0.3l0-0.4c0,0-1.8,0-4.5,0c-8.2,0-25,0.1-27.3,0.1
	c-1.5,0-2.9-0.2-4.5-1c-1.6-0.8-3.3-2.1-5.3-4.2c-3.9-4.2-3.9-9-3.9-9h-0.3l0.3,0v0h-0.3l0.3,0v0l0-64.6l32.2-0.1l0,0
	c0,0,1-0.3,2.8-0.8c6.3-1.7,22.1-5.9,37.2-9.9c7.5-2,14.9-3.9,20.7-5.4c5.9-1.5,10.3-2.6,11.8-2.8c1-0.2,2-0.3,3-0.3
	c6.1,0,11.9,3.4,16.5,8.2c2.7,2.8,4.1,5.5,5,7.4c0.4,1,0.7,1.8,0.8,2.3c0.1,0.3,0.1,0.5,0.1,0.6c0,0.1,0,0.1,0,0.2l0,0l0,0.3h26.2
	l3,15.8h5.5V119h6.6v-0.7h-5.8V85.6h-5.6l-3-15.8h-26.5v0.4l0.4,0c0-0.1-0.7-5.2-6.2-11c-4.7-5-10.6-8.5-17-8.5
	c-1,0-2.1,0.1-3.1,0.3c-1.9,0.3-7.3,1.7-14.5,3.5C110.8,60,74.1,69.9,74.1,69.9l0.1,0.4l0-0.4l-32.9,0.1l0,65.3h0.4l-0.4,0v0
	c0,0.2,0,5.1,4.1,9.5c2,2.2,3.8,3.6,5.5,4.4c1.7,0.8,3.2,1.1,4.8,1.1c3,0,31.8-0.1,31.8-0.1l0.2,0l8.9-9l-0.1-36.3l56.2,0v39.3l6,6
	l63.5-0.1L222.2,149.4z"/>
<path d="M75.6,236.7c0,1.8,0.7,4.5,1.5,7.2c0.9,2.7,1.9,5.3,2.8,6.7c0.7,1.2,2.1,2.4,4.1,3.4c2,1,4.6,1.6,7.7,1.6
	c1.4,0,3-0.1,4.6-0.5c1.1-0.2,4.7-1.1,9.7-2.3c15.2-3.7,43.5-10.7,54.1-13.3l0.3-0.1v-2.9h-0.7v2.6h0.4l-0.1-0.4
	c-7.1,1.7-22,5.4-35.7,8.8c-6.8,1.7-13.4,3.3-18.4,4.5c-5.1,1.2-8.7,2.1-9.7,2.3c-1.6,0.3-3.1,0.4-4.5,0.4c-3,0-5.5-0.6-7.4-1.5
	c-1.9-0.9-3.2-2.1-3.8-3.1c-0.8-1.3-1.9-3.9-2.7-6.5c-0.8-2.7-1.5-5.4-1.5-7H75.6z"/>
<path d="M214.1,118.6V96.8h31.5V119h7.1V86.4h4.6l3.7-15.8l26.1-0.1l0.1-0.3l0-0.1c0.2-0.9,2.5-8.8,9.4-14.1c2.4-1.8,4.3-3,6.1-3.7
	c1.8-0.7,3.4-1,5.3-1c2,0,4.3,0.3,7.2,0.7c2,0.2,7.5,1.5,14.6,3.3c10.7,2.7,25,6.5,36.7,9.6c5.8,1.6,11,3,14.7,4
	c1.9,0.5,3.3,0.9,4.4,1.2c1,0.3,1.6,0.4,1.6,0.4l0,0l30.9,0l0.1,31h28.7l2.8,2.8v45.1h-74.8l-6.9-7.4l0-30.9h-64.5l-0.1,28.5
	l-9.4,9.7h-72v0.7h72.3l9.8-10.2l0.1-28.1h63l0,30.4l7.3,7.8h75.8V104l-3.2-3.2h-28.3l-0.1-31l-31.6,0l0,0.4l0.1-0.4
	c0,0-16-4.4-33-8.9c-8.5-2.2-17.2-4.5-24.4-6.3c-7.1-1.8-12.6-3.1-14.7-3.3c-2.9-0.4-5.2-0.7-7.3-0.7c-1.9,0-3.7,0.3-5.6,1
	c-1.9,0.7-3.9,1.9-6.3,3.8c-7.5,5.8-9.6,14.6-9.6,14.6l0.4,0.1l0-0.4l-26.4,0.1l-3.7,15.8H252v32.6h-5.6V96.1h-33v22.5H214.1z"/>
<polygon points="598.9,107.5 555.5,107.5 546.6,99.5 546.6,71.5 499.5,71.5 499.5,140 490.4,149.4 450.5,149.4 450.5,104 447.4,101 
	447.3,48.7 479.4,39.6 479.4,36.3 507.5,36.3 507.5,39 512,39 512,25.5 533.4,25.5 533.4,39.3 537.5,39.3 537.5,36.3 585.3,36.3 
	593.7,68.6 598.3,68.6 598.3,87.7 596.3,87.7 596.3,91.3 598.5,91.3 598.5,108 599.2,108 599.2,90.5 597,90.5 597,88.4 599.1,88.4 
	599.1,67.8 594.3,67.8 585.9,35.5 536.8,35.5 536.8,38.6 534.2,38.6 534.2,24.8 511.2,24.8 511.2,38.2 508.3,38.2 508.3,35.5 
	478.6,35.5 478.6,39 446.6,48.1 446.7,101.3 449.7,104.3 449.7,150.1 490.7,150.1 500.2,140.3 500.2,72.3 545.8,72.3 545.8,99.8 
	555.2,108.2 598.9,108.2 "/>
<polygon points="598.6,115.6 598.6,115.2 555.5,115.2 545.9,124.9 545.9,152.3 542.6,156.2 449.4,156.2 449.4,188.6 533.8,188.6 
	533.8,185.6 552.9,185.6 552.9,187.9 567.5,187.9 567.5,184.1 598.9,175.7 598.9,124.6 596.7,124.6 596.7,123.7 598.9,123.7 
	598.9,115.2 598.6,115.2 598.6,115.6 598.2,115.6 598.2,123 596,123 596,125.4 598.1,125.4 598.1,175.2 566.7,183.6 566.7,187.2 
	553.7,187.2 553.7,184.8 533.1,184.8 533.1,187.9 450.1,187.9 450.1,156.9 542.9,156.9 546.6,152.6 546.6,125.2 555.8,116 
	598.6,116 598.6,115.6 598.2,115.6 "/>
<polyline points="449.7,187.9 418.5,187.9 418.5,188.6 449.7,188.6 "/>
<polygon points="206,165.4 206,157 294.3,157 303.5,166.5 303.5,195.7 368,195.7 368,163.5 374.3,156.9 399.9,156.9 399.9,169.8 
	417.5,169.8 417.5,156.9 418.2,157 418.1,235.9 383.2,235.9 383.2,230.9 353.2,230.9 353.2,235.9 300.5,235.9 300.5,230.9 
	270.5,230.9 270.5,235.9 253.8,235.9 253.8,220.1 207.7,220.1 207.6,236.3 190.6,236.3 190.6,231.6 159.9,231.6 159.9,236.3 
	107.8,236.3 107.8,232.2 77.9,232.2 77.9,236.3 42,236.3 42,156.9 87.5,157 95.7,166 95.7,203.1 153.4,202.9 153.4,162.3 158.7,157 
	205.3,157 205.3,165.1 206,165.1 206,156.3 158.4,156.3 152.7,162 152.7,202.2 96.4,202.3 96.5,165.7 87.8,156.2 41.3,156.2 
	41.2,237 78.6,237 78.6,233 107.1,233 107.1,237 160.6,237 160.6,232.3 189.8,232.3 189.8,237 208.4,237 208.4,220.9 253,220.9 
	253,236.7 271.2,236.7 271.2,231.6 299.8,231.6 299.8,236.7 354,236.7 354,231.6 382.5,231.6 382.5,236.7 418.8,236.7 418.9,156.2 
	416.8,156.2 416.8,169 400.7,169 400.7,156.2 373.9,156.2 367.3,163.2 367.3,194.9 304.2,194.9 304.2,166.2 294.6,156.3 
	205.3,156.3 205.3,165.4 "/>
<path d="M300.1,236.7c0,0,13.3,4.1,27.6,8.4c7.1,2.1,14.5,4.3,20.6,6c6.1,1.7,10.9,2.9,13,3.2c2.4,0.3,4.5,0.4,6.4,0.4
	c4.6,0,8-0.9,10.7-2.9c1.9-1.4,3.7-4.2,5.1-7.2c1.4-2.9,2.4-6,2.7-7.9l-0.7-0.1c-0.3,1.8-1.3,4.8-2.6,7.7c-1.3,2.9-3.1,5.6-4.8,6.9
	c-2.5,1.8-5.7,2.7-10.3,2.7c-1.9,0-3.9-0.1-6.3-0.4c-2-0.2-6.8-1.5-12.9-3.1c-9.2-2.5-21.2-6.1-31-9c-4.9-1.5-9.2-2.8-12.3-3.7
	c-1.5-0.5-2.8-0.9-3.6-1.1c-0.9-0.3-1.3-0.4-1.3-0.4L300.1,236.7z"/>
<polyline points="400.3,156.9 449.7,156.9 449.7,156.2 400.3,156.2 "/>
<polygon points="45.8,70.6 45.8,75.5 66.2,75.5 66.2,73.1 71.7,70.7 71.4,70 65.4,72.6 65.4,74.7 46.6,74.7 46.6,70.6 "/>
<polygon points="67.9,149.6 67.9,138 82.1,138 82.1,149.8 82.8,149.8 82.8,137.3 67.2,137.3 67.2,149.6 "/>
<polygon points="67.8,144.2 78.6,144.2 78.6,149.6 79.3,149.6 79.3,143.5 67.8,143.5 "/>
<path d="M82.9,150.1"/>
<polygon points="84,156.6 84,162.3 69.7,162.3 69.7,156.7 69,156.7 69,163 84.7,163 84.7,156.6 "/>
<polygon points="41.7,166.9 47.2,166.9 47.2,179.5 41.8,179.5 41.8,180.2 48,180.2 48,166.2 41.7,166.2 "/>
<polygon points="173.1,156.7 173.1,170.5 198.3,170.5 198.4,156.8 197.6,156.8 197.5,169.7 173.9,169.7 173.9,156.7 "/>
<polygon points="184.8,159.3 179,159.3 179,169.9 179.8,169.9 179.8,160.1 184.8,160.1 "/>
<polyline points="188.1,160.2 188.1,170.1 188.8,170.1 188.8,160.2 "/>
<polygon points="220.4,182.7 220.4,183.1 240.6,183.1 240.6,156.3 220.1,156.3 220.1,183.1 220.4,183.1 220.4,182.7 220.8,182.7 
	220.8,157 239.9,157 239.9,182.3 220.4,182.3 220.4,182.7 220.8,182.7 "/>
<polygon points="237.4,156.8 237.4,162 231.9,162 231.9,156.8 231.2,156.8 231.2,162.7 238.2,162.7 238.2,156.8 "/>
<polygon points="229.5,169.6 229.5,169.9 237.9,169.9 237.9,164.7 229.1,164.7 229.1,169.9 229.5,169.9 229.5,169.6 229.8,169.6 
	229.8,165.4 237.2,165.4 237.2,169.2 229.5,169.2 229.5,169.6 229.8,169.6 "/>
<polyline points="220.5,173.8 240.2,173.8 240.2,173.1 220.5,173.1 "/>
<polyline points="238.2,183.1 238.2,220.4 239,220.4 239,183.1 "/>
<polygon points="280.7,231 280.7,221.2 282.3,219.6 303.3,219.6 320.4,202.5 320.4,195.5 319.6,195.5 319.6,202.2 303,218.8 
	282,218.8 279.9,220.9 279.9,231 "/>
<polygon points="303.7,185.4 287.1,185.4 287.1,179.6 303.7,179.6 303.7,178.8 286.3,178.8 286.3,186.1 303.7,186.1 303.7,185.4 "/>
<polygon points="254.8,149.7 254.8,118.3 252.1,118.3 252.1,119 254,119 254,149.7 "/>
<polygon points="285.1,70 285.1,75.4 269.9,75.4 269.9,70 269.2,70 269.2,76.1 285.9,76.1 285.9,70 "/>
<polyline points="367.5,65.1 367.5,111.7 368.2,111.7 368.2,65.1 "/>
<polygon points="387,70.5 393.4,72.2 393.4,75.1 413.5,75.1 413.5,70.1 412.8,70.1 412.8,74.4 394.2,74.4 394.2,71.7 387.2,69.8 "/>
<polygon points="351.9,195.5 351.9,231.6 353.4,231.6 353.4,230.8 352.6,230.8 352.6,195.5 "/>
<polygon points="401.5,156.9 404.1,156.9 406.4,158.5 406.4,169.1 407.1,169.1 407.1,158.1 404.4,156.2 401.5,156.2 "/>
<polygon points="407.1,159.9 410,159.9 410,156.3 409.2,156.3 409.2,159.1 407.1,159.1 "/>
<polygon points="415.5,149.8 415.5,156.2 411.8,156.2 411.8,156.9 416.2,156.9 416.2,149.8 "/>
<polygon points="394.5,149.7 394.5,134.8 401.7,134.8 401.7,136.3 417.5,136.3 417.5,149.9 418.2,149.9 418.2,135.6 402.4,135.6 
	402.4,134.1 393.8,134.1 393.8,149.7 "/>
<polyline points="399.5,134.8 399.5,149.7 400.2,149.7 400.2,134.8 "/>
<polyline points="410.3,145.9 410.3,136.1 409.6,136.1 409.6,145.9 "/>
<polygon points="418.6,169.8 421.4,169.8 421.4,172.3 434,172.3 434,171.6 422.1,171.6 422.1,169.1 418.6,169.1 "/>
<polygon points="430.6,172.1 430.6,177.9 449.6,177.9 449.6,177.1 431.3,177.1 431.3,172.1 "/>
<polyline points="442.7,164.6 442.7,177.6 443.4,177.6 443.5,164.6 "/>
<polyline points="424.9,159.1 443.6,159.1 443.6,158.4 424.9,158.4 "/>
<polyline points="430.4,156.3 430.4,158.5 431.2,158.5 431.2,156.3 "/>
<polyline points="424.9,156.3 424.9,158.5 425.6,158.5 425.6,156.3 "/>
<polyline points="421.4,156.3 421.4,169.3 422.1,169.3 422.1,156.3 "/>
<polyline points="423.9,163.4 423.9,171.8 424.6,171.8 424.6,163.4 "/>
<polyline points="433.3,162.7 433.3,171.8 434,171.8 434,162.7 "/>
<polyline points="482.4,156.7 482.4,188.3 483.1,188.3 483.1,156.7 "/>
<polyline points="499.4,156.7 499.4,188.1 500.1,188.1 500.1,156.7 "/>
<polyline points="515,156.5 515,188.2 515.8,188.2 515.8,156.5 "/>
<polygon points="531.3,156.7 531.3,160.2 540,168.8 547.6,168.8 547.6,185.1 548.4,185.1 548.4,168.1 540.3,168.1 532.1,159.9 
	532.1,156.7 "/>
<polygon points="546.2,136.5 551.1,136.5 563.8,149.2 563.8,153.2 598.3,153.2 598.3,152.4 564.5,152.4 564.5,148.9 551.4,135.8 
	546.2,135.8 "/>
<polygon points="596.5,90.5 578.8,90.5 578.8,96.7 572.9,96.7 572.9,107.8 573.6,107.8 573.6,97.5 579.5,97.5 579.5,91.3 
	596.5,91.3 "/>
<polygon points="594.3,67.8 582.7,67.8 560.3,90.4 546.3,90.4 546.3,91.1 560.6,91.1 583,68.6 594.3,68.6 594.3,67.8 "/>
<polygon points="556.7,36.1 556.7,45.8 543.5,59 543.5,71.6 544.3,71.6 544.3,59.3 557.5,46.1 557.5,36.1 "/>
<polygon points="511.9,39 514.5,39 514.5,71.8 515.3,71.8 515.3,38.3 511.9,38.3 "/>
<polygon points="478.8,39.5 481.7,42.4 481.7,54.9 490.4,54.9 502.1,66.7 502.1,71.7 502.8,71.7 502.8,66.4 490.7,54.2 482.5,54.2 
	482.5,42.1 479.4,39 "/>
<polygon points="476.5,85.7 476.5,92.9 495.6,92.9 499.7,92.9 499.7,92.1 495.6,92.1 477.3,92.1 477.3,85.7 476.5,85.7 "/>
<polygon points="499.8,86.4 495.5,86.4 495.5,83.7 482.1,83.7 482.1,85.4 482.7,85.4 482.8,84.4 494.8,84.4 494.8,87.1 499.8,87.1 
	"/>
<polygon points="450,104.4 465.5,104.4 481.8,120.7 499.8,120.7 499.8,120 482.1,120 465.8,103.6 450,103.6 "/>
<polyline points="205.3,164.7 197.7,164.7 197.7,165.5 205.3,165.5 "/>
<polyline points="533.3,39.1 533.3,72.3 534.1,72.3 534.1,39.1 "/>
<polygon points="450.2,133.3 464.9,133.3 464.9,138.2 477.6,138.2 477.6,149.7 478.3,149.7 478.3,137.5 465.7,137.5 465.7,132.5 
	450.2,132.5 450.2,133.3 "/>
<polyline points="106.8,104.8 106.8,101 106.4,101 106.4,104.8 "/>
<path class="st0" d="M167.5,156.3"/>
<path class="st0" d="M153,161.8"/>
<path class="st0" d="M217,156.3"/>
<path class="st0" d="M209.8,156.3"/>
<path d="M209.9,156.2"/>
<path d="M209.8,156.5"/>
<g>
	<text transform="matrix(1 0 0 1 505.4814 153.8824)" class="st2 st7 st9">ATRIUM C</text>
</g>
<polyline points="418.1,100.9 417.5,136 418.2,136 418.8,100.9 "/>
<polyline points="465.9,156.7 465.9,188.3 466.7,188.3 466.7,156.7 "/>
<polyline points="142.1,104.5 142.1,52.7 141.3,52.7 141.3,104.5 "/>
<text transform="matrix(1 0 0 1 470.4775 206.7057)" class="st2 st10"> </text>
<g>
	<text transform="matrix(1 0 0 1 315.6592 153.8824)" class="st2 st7 st9">ATRIUM B</text>
</g>
<g>
	<text transform="matrix(1 0 0 1 103.6592 153.8824)" class="st2 st7 st9">ATRIUM A</text>
</g>
<polygon points="95.7,188.9 84.2,188.9 84.2,195.4 54.5,195.4 54.5,188.8 41.5,188.8 41.5,189.5 53.8,189.5 53.8,196.1 84.9,196.1 
	84.9,189.7 95.7,189.7 "/>
<g>
	<text transform="matrix(1 0 0 1 119.4766 256.774)" class="st2 st7 st11">OFFICE SPACE</text>
</g>
<g>
	<text transform="matrix(1 0 0 1 286.9219 255.6178)" class="st2 st7 st11">OFFICE SPACE</text>
</g>
<polygon points="207.2,118.6 207.2,129.9 214.1,129.9 214.1,149.5 214.9,149.5 214.9,129.2 208,129.2 208,118.6 "/>
<polygon points="280,149.9 280,131.2 286.9,131.2 286.9,70.1 286.2,70.1 286.2,130.5 279.3,130.5 279.3,149.9 "/>
<polyline points="107.1,104.2 107.1,61.6 106.3,61.6 106.3,104.2 "/>
<g>
	<line x1="146.9" y1="169.1" x2="147.7" y2="169.1"/>
</g>
<g>
	<rect x="580.5" y="102.8" width="16.5" height="17.2"/>
	<path class="st0" d="M591.2,114.6c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5h-0.3
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V114.6z"/>
	<path class="st0" d="M584.7,113h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2H583v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2H586v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V113z"/>
	<path class="st0" d="M592.4,106.8c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C593.7,106.2,593.1,106.8,592.4,106.8"/>
	<path class="st0" d="M583.3,105.4c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C583.9,106.8,583.3,106.2,583.3,105.4"/>
</g>
<g>
	<rect x="400.3" y="144.5" width="16.5" height="17.2"/>
	<path class="st0" d="M411,156.3c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5h-0.3
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V156.3z"/>
	<path class="st0" d="M404.5,154.7h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2h-0.2v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2h-0.2v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V154.7z"/>
	<path class="st0" d="M412.2,148.5c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C413.6,147.9,413,148.5,412.2,148.5"/>
	<path class="st0" d="M403.1,147.1c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C403.7,148.5,403.1,147.9,403.1,147.1"/>
</g>
<g>
	<rect x="176.5" y="144.5" width="16.5" height="17.2"/>
	<path class="st0" d="M187.3,156.3c-0.3,0-0.6,0-0.8,0c-0.2,0-0.3-0.1-0.3-0.3c0.2-1,0.4-2,0.6-2.9c0.2-0.8,0.3-1.6,0.5-2.5h-0.3
		c-0.1,0.4-0.1,0.7-0.2,1.1c-0.1,0.6-0.2,1.2-0.4,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.3,0.3-0.5,0.2c-0.2,0-0.3-0.2-0.4-0.4
		c0-0.1,0-0.3,0-0.4c0.2-1.2,0.5-2.4,0.7-3.6c0.1-0.4,0.2-0.6,0.7-0.7c0.2,0,0.4-0.1,0.7-0.1c0.9,0,1.7,0,2.6,0c0.2,0,0.3,0,0.5,0.1
		c0.3,0.1,0.5,0.3,0.6,0.6c0.2,0.9,0.3,1.7,0.5,2.6c0.1,0.4,0.1,0.7,0.2,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-0.5,0.4-0.7,0.1
		c-0.1-0.1-0.2-0.3-0.2-0.5c-0.2-0.9-0.4-1.8-0.5-2.8c0,0,0-0.1,0-0.1h-0.3c0.1,0.5,0.2,1,0.3,1.4c0.2,1.3,0.5,2.6,0.7,3.9
		c0,0.3,0,0.4-0.3,0.4c-0.3,0-0.5,0-0.8,0v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.2,0.3-0.4,0.3
		c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4c0-1,0-2.1,0-3.1v-0.2h-0.3v0.2c0,1,0,2.1,0,3.1c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.2-0.2,0.3-0.4,0.3c-0.2,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.2-0.1-0.4c0-1,0-2.1,0-3.1V156.3z"/>
	<path class="st0" d="M180.8,154.7h-0.2v0.2c0,1.5,0,3.1,0,4.6c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.7-0.1-0.8-0.5c0-0.1,0-0.2,0-0.3
		c0-3,0-6,0-9v-0.2h-0.2v0.2c0,1.2,0,2.3,0,3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-0.1-0.1-0.2-0.3-0.2-0.5
		c0-1.3,0-2.6,0-3.8c0-0.7,0.6-1.3,1.3-1.4c1,0,1.9,0,2.9,0c0.7,0,1.3,0.6,1.3,1.3c0,1.3,0,2.6,0,3.9c0,0,0,0.1,0,0.1
		c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.5-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-3.5v-0.2H182v0.2c0,3,0,6,0,9c0,0.1,0,0.2,0,0.3
		c-0.1,0.3-0.4,0.5-0.7,0.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.1,0-0.1,0-0.2v-4.6V154.7z"/>
	<path class="st0" d="M188.5,148.5c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3
		C189.8,147.9,189.2,148.5,188.5,148.5"/>
	<path class="st0" d="M179.3,147.1c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
		C179.9,148.5,179.3,147.9,179.3,147.1"/>
</g>
<line class="st1" x1="482.7" y1="85.4" x2="446.6" y2="85.4"/>
<rect class="st12" width="636" height="285.3"/>
</svg>
	<store-modal
      :title="store.name"
      :image="store.business_image"
      :suite="store.suite"
      :website="store.website"
      :contact="store.contact"
      :phone="store.phone"
      :email="store.email"
      :target="target"
      :targetzone="targetzone"
    />
	</div>
</template>

<script>
// @ is an alias to /src
import StoreModal from '@/components/StoreModal'
export default {
  name: 'floor3',
  components:{
    StoreModal,
  },
  data(){
    return {
      store:'B-100',
	  targetzone:'C-370',
	  target: null,
    }
  },
  methods:{
    clickStore(e) {
		setTimeout(() => {
                  this.$root.$emit('bv::hide::popover');
            }, 0);

			this.targetzone = '';
      e.target.className.baseVal = "selected";
      this.target = e.target.id;
      this.store = this.storeData(this.target);
      this.targetzone = this.target;

      //this.$bvModal.show('modal-center');
	  setTimeout(() => {
                  this.$root.$emit('bv::show::popover',this.targetzone)
            }, 1000);
    },
    resetModal() {
      console.log("reset modal");
    },

    storeData(payload) {
      return this.stores.find(x => x.suite === payload)
    },
	showStorePopover(){
          this.targetzone = this.$route.params.id;
          if (typeof(this.$route.params.id) !== 'undefined'){

		
			setTimeout(() => {
					this.store = this.storeData(this.$route.params.id);
					this.$root.$emit('bv::show::popover',this.targetzone);
				}, 400);
		}
    }
  },
  mounted() {
    window.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  },
  computed:{
    stores() {
      return this.$store.state.suites;
    }
  },

  created: function(){
        if (typeof(this.$route.params.id) !== 'undefined'){
			this.showStorePopover()
	}
  }
}
</script>

<style scoped type="text/css">
.selected {
	stroke: #002868 !important;
	stroke-width: 1px;
	stroke-linejoin: round;
	fill: #FFFFFF !important;
	cursor: pointer;
	fill-opacity: .7;
	}
	.st0{fill:#FFFFFF;}
	.st1{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st2{font-family: 'Montserrat', sans-serif;}
	.st3{font-size:11px;}
	.st4{fill:#8FC3E1;}
	.st5{fill:#C4E1F6;}
	.st6{fill:#D9E09E;}
	.st7{fill:#000100;}
	.st8{font-family: 'Montserrat', sans-serif;}
	.st9{font-size:5.9575px;}
	.st10{font-size:6.7022px;}
	.st11{font-size:6.9504px;}
	.st12{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
</style>